<template>
  <div
    :id="anchorId"
    :class="{ 'tw-mb-4': contentHeader, 'tw-w-full': fullWidth }"
  >
    <div
      class="tw-flex tw-flex-row tw-justify-between tw-gap-4"
      :class="[customClass, { 'tw-flex-wrap': wrap }]"
    >
      <div
        :class="{ 'tw-w-full': fullWidth }"
        class="tw-flex tw-flex-col tw-justify-center tw-gap-4"
      >
        <loading-title v-if="panelLoading" />
        <h2
          v-if="!panelLoading && text"
          class="tw-flex tw-items-baseline"
          :class="[
            !status && statusBgStyle,
            textAlign,
            xsmall ? 'tw-h6' : large ? 'tw-h3' : small ? 'tw-h5' : 'tw-h4',
            {
              'tw-items-center': icon,
              'tw-font-medium': mediumWeight,
              'tw-font-bold': boldWeight,
            },
          ]"
        >
          <font-awesome-icon
            v-if="icon"
            :icon="icon"
            class="tw-fill-current tw-mr-2"
          />

          {{ text }}{{ status ? ': ' : '' }}
          <span v-if="status" class="tw-pl-1.5" :class="statusBgStyle">
            {{ status }}
          </span>

          <div
            v-if="$slots.tip"
            class="tw-flex tw-items-center sm:tw-items-end tw-flex-1 tw-justify-end"
          >
            <slot name="tip" />
          </div>
        </h2>

        <div
          v-if="$slots.content || $slots.noHtmlContent"
          class="tw-text-md"
          :class="[
            textAlign,
            {
              'tw-mb-6': !noContentMargin && basePanelHasContent,
              'tw-w-full': fullWidth,
            },
          ]"
        >
          <loading-content v-if="panelLoading" hide-title></loading-content>
          <div
            v-else
            :class="{ 'tw-content': $slots.content && !noContentMargin }"
          >
            <p v-if="$slots.noHtmlContent" class="tw-text-p-md">
              <slot name="noHtmlContent" />
            </p>
            <slot v-else name="content" />
          </div>
        </div>
      </div>

      <slot v-if="$slots.info && !$slots.tip" name="info" />

      <div
        v-if="$slots.buttons && !panelLoading"
        class="tw-flex tw-flex-col tw-justify-center tw-space-y-2"
      >
        <slot name="buttons" />
      </div>
    </div>
  </div>
</template>
<script>
import LoadingContent from '@components/Loading/LoadingContent.vue'
import LoadingTitle from '@components/Loading/LoadingTitle.vue'
import { computed, toRefs, getCurrentInstance } from 'vue'
export default {
  components: {
    LoadingTitle,
    LoadingContent,
  },
  props: {
    text: {
      type: String,
      default: '',
    },
    status: {
      type: String,
      default: '',
    },
    contentHeader: {
      type: Boolean,
      default: false,
    },
    noContentMargin: {
      type: Boolean,
      default: false,
    },
    panelLoading: {
      type: Boolean,
      default: false,
    },
    customClass: {
      type: String,
      default: '',
    },
    anchorId: {
      type: String,
      default: undefined,
    },
    align: {
      type: String,
      default: 'left',
      validator: function (value) {
        return ['left', 'center', 'right'].indexOf(value) !== -1
      },
    },
    primary: {
      type: Boolean,
      default: false,
    },
    success: {
      type: Boolean,
      default: false,
    },
    danger: {
      type: Boolean,
      default: false,
    },
    warning: {
      type: Boolean,
      default: false,
    },
    info: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: [String, Array],
      default: null,
    },
    large: {
      type: Boolean,
      default: false,
    },
    small: {
      type: Boolean,
      default: false,
    },
    xsmall: {
      type: Boolean,
      default: false,
    },
    mediumWeight: {
      type: Boolean,
      default: false,
    },
    boldWeight: {
      type: Boolean,
      default: false,
    },
    fullWidth: {
      type: Boolean,
      default: true,
    },
    wrap: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const { align, primary, success, danger, warning, info } = toRefs(props)

    const textAlign = computed(() => {
      if (align.value === 'center') {
        return 'tw-justify-center tw-text-center'
      } else if (align.value === 'right') {
        return 'tw-justify-end tw-text-right'
      } else {
        return 'tw-justify-start tw-text-left'
      }
    })

    const statusBgStyle = computed(() => {
      if (success.value) {
        return 'tw-text-success'
      } else if (danger.value) {
        return 'tw-text-danger'
      } else if (warning.value) {
        return 'tw-text-warning'
      } else if (info.value) {
        return 'tw-text-info'
      } else if (primary.value) {
        return 'tw-text-primary'
      } else {
        return ''
      }
    })

    const basePanelHasContent = computed(
      () => !!getCurrentInstance()?.parent?.slots?.default
    )

    return {
      basePanelHasContent,
      statusBgStyle,
      textAlign,
    }
  },
}
</script>
