import { clone } from '@helpers/utils.js'
import { store } from '@stores'
import { unref } from 'vue'
import {
  KEYS_REPORTS_SUMMARY,
  KEYS_REPORTS_DETAILS,
} from '@config/storeKeys.js'
import {
  REPORT_TYPE_OPTIONS_ADMIN,
  REPORT_TYPE_NOT_WORKING,
  DASHBOARD_REPORT_TYPES,
  REPORTS_DASHBOARD_TABS,
  REPORT_TYPE_DMCA,
  STATUS_DELETED,
} from '@config/options.js'

const { createStore, generateId, getStore } = store()

let prevStore = null
/**
 * @param {'mods' | 'articles' | 'members'} resourceType
 */
export default function (nameId, resourceType, filteredTypes) {
  function _getAllTypes(resource) {
    const _filteredTypes = unref(filteredTypes)
    return Object.entries(resource.summary.type_count_map).filter(
      ([t, count]) =>
        count > 0 &&
        (_filteredTypes.find((ft) => ft.value === +t) || !_filteredTypes.length)
    )
  }

  function _transformSummaries(summaries) {
    return summaries.map((resource) => {
      const allTypes = _getAllTypes(resource)

      const type =
        allTypes.length === 1
          ? REPORT_TYPE_OPTIONS_ADMIN.find(
              (o) => o.value === Number(allTypes[0][0])
            )?.text
          : `${allTypes.length} types`
      return {
        ...resource,
        summary: {
          ...resource.summary,
          type,
        },
      }
    })
  }

  function _removeReportsFromTotal(summary, tab, reportsToRemove) {
    let _summary = clone(summary)
    _summary.summary.total -= reportsToRemove.length
    reportsToRemove.forEach((r) => {
      _summary.summary.type_count_map[r.type]--
    })
    if (tab === REPORTS_DASHBOARD_TABS.ALL) {
      _summary = _transformSummaries([_summary])[0]
    }
    return _summary
  }

  function _checkUniqueTypes(tab, r) {
    const isDMCA =
      tab === REPORTS_DASHBOARD_TABS.DMCA && r.type === REPORT_TYPE_DMCA
    const isNotWorking =
      tab === REPORTS_DASHBOARD_TABS.NOT_WORKING &&
      r.type === REPORT_TYPE_NOT_WORKING

    if (
      [
        REPORTS_DASHBOARD_TABS.NOT_WORKING,
        REPORTS_DASHBOARD_TABS.DMCA,
      ].includes(tab)
    ) {
      return isDMCA || isNotWorking
    }

    return true
  }

  function _addToResolvedSummary(tab, resourceId, summary, reports) {
    const resolved = getReportsSummary(REPORTS_DASHBOARD_TABS.RESOLVED).value
    if (!resolved || resolved.data.some((r) => r.resource_id === resourceId))
      return

    let _summary = clone(summary)
    if (reports?.length) {
      _summary.summary.type_count_map = {}
      _summary.summary.total = 0
      reports.forEach((r) => {
        if (_checkUniqueTypes(tab, r)) {
          _summary.summary.type_count_map[r.type] =
            (_summary.summary.type_count_map[r.type] || 0) + 1
          _summary.summary.total++
        }
      })
    }

    setReportsSummary(REPORTS_DASHBOARD_TABS.RESOLVED, {
      ...resolved,
      data: [...resolved.data, _summary],
      result_count: resolved.result_count + 1,
      result_total: resolved.result_total + 1,
    })
  }

  function _getSummaryCount(tab, summary) {
    if (resourceType === DASHBOARD_REPORT_TYPES.USERS)
      return summary.summary.total

    const _filteredTypes = unref(filteredTypes)

    if (tab === REPORTS_DASHBOARD_TABS.DMCA) {
      return summary.summary.type_count_map[REPORT_TYPE_DMCA]
    }

    if (tab === REPORTS_DASHBOARD_TABS.NOT_WORKING) {
      return summary.summary.type_count_map[REPORT_TYPE_NOT_WORKING]
    }

    return _filteredTypes.length
      ? Object.entries(summary.summary.type_count_map).reduce(
          (acc, [t, count]) => {
            const value = _filteredTypes.find((ft) => ft.value === +t)
              ? count
              : 0
            return acc + value
          },
          0
        )
      : summary.summary.total
  }

  function _removeFromSummary(
    tab,
    resourceId,
    reportsToRemove,
    markDeleted = false
  ) {
    const summaries = getReportsSummary(tab).value
    const summary = summaries?.data.find((s) => s.resource_id === resourceId)
    if (!summary) return

    if (markDeleted) summary.metadata.status = STATUS_DELETED

    if (
      !reportsToRemove?.length ||
      reportsToRemove.length === _getSummaryCount(tab, summary)
    ) {
      // Remove the entire summary
      setReportsSummary(
        tab,
        {
          ...summaries,
          data: summaries.data.filter((s) => s.resource_id !== resourceId),
          result_count: summaries.result_count - 1,
          result_total: summaries.result_total - 1,
        },
        false
      )
    } else {
      const _summary = _removeReportsFromTotal(summary, tab, reportsToRemove)
      updateReportsSummary(tab, resourceId, _summary)
    }

    _addToResolvedSummary(tab, resourceId, summary, reportsToRemove)
  }

  function getReportsSummary(tab) {
    return getStore(generateId(KEYS_REPORTS_SUMMARY, nameId, resourceType, tab))
  }

  function setReportsSummary(
    tab,
    summaries,
    transform = true,
    _resourceType = resourceType
  ) {
    if (transform && summaries) {
      if (tab === REPORTS_DASHBOARD_TABS.ALL) {
        summaries.data = _transformSummaries(summaries.data)
      } else if (
        tab === REPORTS_DASHBOARD_TABS.RESOLVED ||
        tab === REPORTS_DASHBOARD_TABS.UNRESOLVED
      ) {
        summaries.data = _transformSummaries(summaries.data)
      }
    }

    createStore(
      generateId(KEYS_REPORTS_SUMMARY, nameId, _resourceType, tab),
      summaries
    )
  }

  function addReportsSummaries(tab, summaries, next = true) {
    const exSummaries = getReportsSummary(tab).value

    if (tab === REPORTS_DASHBOARD_TABS.ALL) {
      summaries.data = _transformSummaries(summaries.data)
    } else if (
      tab === REPORTS_DASHBOARD_TABS.RESOLVED ||
      tab === REPORTS_DASHBOARD_TABS.UNRESOLVED
    ) {
      summaries.data = _transformSummaries(summaries.data)
    }

    if (exSummaries) {
      summaries.result_count += exSummaries.result_count
      summaries.data = next
        ? [...exSummaries.data, ...summaries.data]
        : [...summaries.data, ...exSummaries.data]
    }

    setReportsSummary(tab, summaries, false)
  }

  function updateReportsSummary(tab, resourceId, summary) {
    const summaries = getReportsSummary(tab).value
    const index = summaries?.data.findIndex(
      (summary) => summary.resource_id === resourceId
    )
    if (index >= 0) {
      const data = [...summaries.data]
      data[index] = summary
      setReportsSummary(tab, { ...summaries, data }, false)
    }
  }

  function getReportsDetails(tab, resourceId) {
    return getStore(
      generateId(KEYS_REPORTS_DETAILS, nameId, resourceType, tab, resourceId)
    )
  }

  function setReportsDetails(tab, resourceId, reports) {
    createStore(
      generateId(KEYS_REPORTS_DETAILS, nameId, resourceType, tab, resourceId),
      reports
    )
  }

  function resolveReports(
    tab,
    resourceId,
    reports,
    username,
    markDeleted = false
  ) {
    prevStore = {
      tab,
      resourceId,
      summaries: getReportsSummary(tab).value,
      details: getReportsDetails(tab, resourceId).value,
      resolved: getReportsDetails(REPORTS_DASHBOARD_TABS.RESOLVED, resourceId)
        .value,
    }
    _removeFromSummary(tab, resourceId, reports, markDeleted)
    if (prevStore.details) {
      const reportIds = reports?.map((r) => r.id)
      setReportsDetails(tab, resourceId, {
        ...prevStore.details,
        result_total: reports
          ? Math.max(prevStore.details.result_total - reports.length, 0)
          : 0,
        data: prevStore.details.data.filter(
          (report) => reportIds && !reportIds.includes(report.id)
        ),
      })
    }
    if (prevStore.resolved) {
      const toResolve = reports || prevStore.details?.data || []
      setReportsDetails(REPORTS_DASHBOARD_TABS.RESOLVED, resourceId, {
        ...prevStore.resolved,
        result_total: reports
          ? prevStore.resolved.result_total + reports.length
          : 0,
        data: [
          ...prevStore.resolved.data,
          ...toResolve.map((r) => ({
            ...r,
            resolved: true,
            resolved_by: {
              username,
            },
          })),
        ],
      })
    }
  }

  function rollBackResolve() {
    if (!prevStore) return

    const { tab, resourceId, summaries, details, resolved } = prevStore

    setReportsSummary(tab, summaries, false)
    setReportsDetails(tab, resourceId, details)
    setReportsDetails(REPORTS_DASHBOARD_TABS.RESOLVED, resourceId, resolved)

    prevStore = null
  }

  return {
    updateReportsSummary,
    addReportsSummaries,
    getReportsSummary,
    setReportsSummary,
    getReportsDetails,
    setReportsDetails,
    rollBackResolve,
    resolveReports,
  }
}
