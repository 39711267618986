import { useEventListener, useMousePressed, useMouse } from '@vueuse/core'
import { ref, watch, computed } from 'vue'

export default function (scrollableRef, watchCondition) {
  const { x } = useMouse()
  const { pressed } = useMousePressed({ target: scrollableRef })
  const offsetX = ref(0)
  const offsetMax = ref(0)
  const overflowShadowClass = computed(() => ({
    'tw-shadow-dark-1 tw-shadow-cell-x':
      -offsetX.value < offsetMax.value && offsetX.value < 0,
    'tw-shadow-dark-1 tw-shadow-cell-left':
      offsetX.value < 0 && -offsetX.value >= offsetMax.value,
    'tw-shadow-dark-1 tw-shadow-cell-right':
      -offsetX.value < offsetMax.value && offsetX.value >= 0,
  }))

  let lastX = 0

  useEventListener('resize', calcOffsetMax)

  watch(x, (_x) => {
    if (watchCondition && !watchCondition?.value) return

    if (pressed.value) {
      offsetX.value += _x - lastX
      offsetX.value = Math.min(0, Math.max(-offsetMax.value, offsetX.value))
    }

    lastX = _x
  })

  function calcOffsetMax() {
    offsetX.value = 0
    if (scrollableRef.value) {
      offsetMax.value =
        scrollableRef.value.scrollWidth - scrollableRef.value.clientWidth
    }
  }

  return {
    overflowShadowClass,
    calcOffsetMax,
    offsetMax,
    offsetX,
  }
}
