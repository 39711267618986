import { requestAll } from '@helpers/requestAll.js'
import { queryString } from '@helpers/utils.js'
import { createClient } from '@services'
import qs from 'qs'

const { get, post, put, del, uploadFile } = createClient()

/* API request to get all modio staff */
export async function getAdminUsersListRequest(queries) {
  const { data } = await get(`/admin/users${queryString(queries)}`)

  return data
}

export async function getMergeUsersSummaryRequest(user1Id, user2Id) {
  const { data } = await get(`/admin/users/merge/${user1Id}/${user2Id}`)
  return data
}

export function mergeUsersRequest(from_user_id, to_user_id) {
  return post('/admin/users/merge', qs.stringify({ from_user_id, to_user_id }))
}

export async function adminGetUserRequest(nameId) {
  const { data } = await get(`/admin/users/@${nameId}`)
  return data
}

export function adminEditUserRequest(nameId, data) {
  return put(`/admin/users/@${nameId}/info`, qs.stringify(data))
}

export function adminGetUserLogsRequest(nameId) {
  return Promise.all([
    requestAll(`/admin/users/@${nameId}/logs/activity`),
    requestAll(`/admin/users/@${nameId}/logs/status`),
    get(`/admin/users/@${nameId}/logs/loginhistory`).then((res) => res.data),
  ])
}

/* API request to update user's status */
export async function updateUserStatusRequest(nameId, permission, reason) {
  const { data } = await put(
    `/admin/users/@${nameId}/status`,
    qs.stringify({
      permission,
      reason,
    })
  )
  return data
}

/* API request to delete a user's account */
export function deleteUserAccountRequest(nameId) {
  return del(`/admin/users/@${nameId}`)
}

/* API request to get user's email diagnostics */
export async function adminGetUserDiagnosticsRequest(userNameId) {
  const { data } = await get(`/admin/users/@${userNameId}/diagnostics`)
  return data
}

/* API request to unlock a user's email */
export async function unlockUsersEmailRequest(userNameId) {
  const { data } = await put(`/admin/users/@${userNameId}/unlock`)
  return data
}

/* API request to reset a user's hard email bounce count */
export async function resetUsersEmailBouncesRequest(userNameId) {
  const { data } = await put(`/admin/users/@${userNameId}/bounces`)
  return data
}

/* API request to upload user avatar */
export async function uploadOtherUserAvatarRequest(
  userNameId,
  upload,
  onUploadProgress
) {
  const { data } = await uploadFile(
    `/admin/users/@${userNameId}/media`,
    upload,
    onUploadProgress
  )
  return data
}
