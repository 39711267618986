<template>
  <div ref="target" class="tw-relative">
    <base-button
      v-if="tipHover"
      text-link
      auto-height
      :primary="primary && !warning"
      :warning="warning"
      icon="question-circle"
      @click="tipClick"
    />

    <template v-else>
      <div ref="slotContent">
        <slot />
      </div>
    </template>

    <teleport :disabled="!fixed" to="body">
      <div
        v-if="text || $slots.toast || $slots.custom"
        class="tw-shrink-0 tw-flex tw-items-center tw-text-xs tw-z-10 tw-transform tw-transition-opacity tw-text-left tw-w-60"
        :class="[
          fixed ? '' : `tw-absolute ${tooltipPos}`,
          hoverClass,
          {
            'tw-pointer-events-none': !show && !$slots.custom,
            'tw-hidden md:tw-block': hideOnSm,
            'tw-block lg:tw-hidden': hideOnLg,
          },
        ]"
        :style="tooltipFixedStyle"
      >
        <div
          ref="tooltip"
          class="tw-inline-flex tw-rounded tw-py-1.5 tw-px-3 tw-relative"
          :class="[
            `tw-bg-${background} tw-text-${background}-text`,
            overflow.tt,
            { 'tw-px-1 tw-py-2.5': extraPaddings },
          ]"
        >
          <div
            class="tw-absolute tw-flex tw-items-center tw-justify-center tw-transform tw-h-2.5 tw-w-4"
            :class="[arrowPosClass, overflow.arrow]"
          >
            <font-awesome-icon
              icon="caret-up"
              :class="`tw-text-${background}`"
              size="2x"
            />
          </div>

          {{ text }}

          <slot name="toast" />
          <slot name="custom" />
        </div>
      </div>
    </teleport>
  </div>
</template>

<script>
import { toRefs, computed, ref, watch } from 'vue'
import { useEventListener } from '@vueuse/core'
import { useBoundaries } from '@composables'

export default {
  props: {
    position: {
      type: String,
      default: 'top',
      validator: function (value) {
        return (
          [
            'top',
            'bottom',
            'left',
            'right',
            'top left',
            'top right',
            'bottom left',
            '',
          ].indexOf(value) !== -1
        )
      },
    },
    text: {
      type: String,
      default: '',
    },
    background: {
      type: String,
      default: 'primary',
    },
    show: {
      type: Boolean,
      default: false,
    },
    tipHover: {
      type: Boolean,
      default: false,
    },
    outsideMain: {
      type: Boolean,
      default: false,
    },
    hideOnSm: {
      type: Boolean,
      default: false,
    },
    hideOnLg: {
      type: Boolean,
      default: false,
    },
    primary: {
      type: Boolean,
      default: true,
    },
    warning: {
      type: Boolean,
      default: false,
    },
    extraPaddings: {
      type: Boolean,
      default: false,
    },
    autoPos: {
      type: Boolean,
      default: false,
    },
    fixed: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { slots }) {
    const { position, fixed, autoPos, text, show, tipHover, outsideMain } =
      toRefs(props)
    const { mainBottom, mainRight, mainLeft, mainTop, setBoundaryVars } =
      useBoundaries()
    const target = ref(null)
    const tooltip = ref(null)
    const tooltipVars = ref({})
    const slotContent = ref(null)
    const THRESHOLD = 20
    const hovered = ref(false)

    if (!tipHover.value && slots.default) {
      watch(slots.default, () => {
        if (hovered.value) {
          // close any tootlips if slot data changes
          hovered.value = false
        }
      })
    }

    useEventListener(target, 'mouseenter', handleMouseEnter)
    useEventListener(target, 'mouseleave', handleMouseLeave)

    if (fixed.value) {
      useEventListener(tooltip, 'mouseenter', handleMouseEnter)
      useEventListener(tooltip, 'mouseleave', handleMouseLeave)
    }

    const hoverClass = computed(() =>
      hovered.value || show.value
        ? 'tw-opacity-100 tw-visible'
        : 'tw-opacity-0 tw-invisible'
    )

    const tooltipPos = computed(() => {
      let _position = getTooltipPosition()

      if (_position === 'bottom') {
        return 'tw-justify-center tw--bottom-0.5 tw-translate-y-full tw-left-1/2 tw--translate-x-1/2 tw-pt-2'
      } else if (_position === 'left') {
        return 'tw-justify-end tw-bottom-1/2 tw-translate-y-1/2 tw-left-0 tw--translate-x-full tw-pr-2'
      } else if (_position === 'right') {
        return 'tw-justify-start tw-top-1/2 tw--translate-y-1/2 tw-right-0 tw-translate-x-full tw-pl-2'
      } else if (_position === 'top left') {
        return 'tw-justify-end tw--top-0.5 tw--translate-y-full tw-right-1/2 tw-translate-x-4 tw-pb-2'
      } else if (_position === 'top right') {
        return 'tw-justify-start tw--top-0.5 tw--translate-y-full tw-left-1/2 tw--translate-x-4 tw-pb-2'
      } else if (_position === 'bottom left') {
        return 'tw-justify-end tw--bottom-0.5 tw-translate-y-full tw-right-1/2 tw-translate-x-4 tw-pt-2'
      }

      // Top
      return 'tw-justify-center tw--top-0.5 tw--translate-y-full tw-left-1/2 tw--translate-x-1/2 tw-pb-2'
    })

    const arrowPosClass = computed(() => {
      let _position = getTooltipPosition()

      if (_position === 'bottom') {
        return 'tw--top-2 tw-left-1/2 tw--translate-x-1/2'
      } else if (_position === 'left') {
        return 'tw-top-1/2 tw--translate-y-1/2 tw--right-3 tw-rotate-90'
      } else if (_position === 'right') {
        return 'tw-top-1/2 tw--translate-y-1/2 tw--left-3 tw--rotate-90'
      } else if (_position === 'top left') {
        return 'tw--bottom-2 tw-right-2 tw-rotate-180'
      } else if (_position === 'bottom left') {
        return 'tw--top-2 tw-right-2'
      } else if (_position === 'top right') {
        return 'tw--bottom-2 tw-left-2 tw-rotate-180'
      } else if (_position === 'top') {
        return 'tw--bottom-2 tw-left-1/2 tw--translate-x-1/2 tw-rotate-180'
      } else {
        return 'tw--bottom-2 tw-left-1/2 tw--translate-x-1/2 tw-rotate-180'
      }
    })

    const tooltipFixedStyle = computed(() => {
      if (!fixed.value) return null
      if (!slotContent.value || !tooltipVars.value?.width)
        return { position: 'fixed', overflow: 'hidden' }

      const contentBounding = slotContent.value.getBoundingClientRect()
      const centerX =
        contentBounding.left -
        tooltipVars.value.width * 0.5 +
        contentBounding.width * 0.5

      const arrowHeight = 10
      const contentTop =
        contentBounding.top - tooltipVars.value.height - arrowHeight

      return { position: 'fixed', left: `${centerX}px`, top: `${contentTop}px` }
    })

    const overflow = computed(() => {
      if (outsideMain.value || autoPos.value || fixed.value) {
        return ''
      } else if (tooltipVars.value.top - THRESHOLD < mainTop.value) {
        return { tt: 'tw-mt-8', arrow: '' }
      } else if (tooltipVars.value.bottom + THRESHOLD > mainBottom.value) {
        return { tt: 'tw-mb-8', arrow: '' }
      } else if (tooltipVars.value.left - THRESHOLD < mainLeft.value) {
        return { tt: 'tw-ml-10', arrow: 'tw-pl-10' }
      } else if (
        tooltipVars.value.right + THRESHOLD > mainRight.value &&
        !position.value.includes('left')
      ) {
        return { tt: 'tw-mr-12', arrow: 'tw-pr-12' }
      }
      return ''
    })

    function tipClick() {
      hovered.value = !hovered.value
    }

    function getTooltipPosition() {
      if (autoPos.value) {
        const toBottom = tooltipVars.value.top - THRESHOLD < mainTop.value
        const toLeft = tooltipVars.value.right + THRESHOLD > mainRight.value
        const toRight = tooltipVars.value.left - THRESHOLD < mainLeft.value

        return `${toBottom ? 'bottom' : 'top'}${toLeft ? ' left' : toRight ? ' right' : ''}`
      }

      return position.value
    }

    function handleMouseEnter() {
      if ((slots.custom || text.value) && !slots.toast) {
        hovered.value = true
        tooltipVars.value = tooltip.value.getBoundingClientRect()
        setBoundaryVars('screenHeight', document.documentElement.scrollHeight)
      }
    }

    function handleMouseLeave() {
      if ((slots.custom || text.value) && !slots.toast) {
        hovered.value = false
        tooltipVars.value = {}
      }
    }

    return {
      tooltipFixedStyle,
      arrowPosClass,
      tooltipVars,
      slotContent,
      tooltipPos,
      hoverClass,
      overflow,
      tipClick,
      tooltip,
      target,
    }
  },
}
</script>
