import { METRICS_DISABLED } from '@config'
import { clone } from '@helpers/utils.js'
import { ref, watch } from 'vue'
import {
  METRICS_TRANSACTION_TYPES,
  METRICS_CURRENCY_TYPES,
  STANDARD_EVENT_TYPES,
  GROUP_BY_OPTIONS,
  METRICS_TYPES,
  RECORD_TYPES,
} from '@config/chartOptions.js'
import {
  useMetricsDates,
  useMetricsData,
  useAsync,
  addToast,
} from '@composables'
import {
  getTrendingTagDownloadsByCreatorsRequest,
  getTotalTagCategoryDownloadsRequest,
  getTrendingCreatorDownloadsRequest,
  getCreatorCountryDownloadsRequest,
  getDownloadsByCategoriesRequest,
  getTrendingModDownloadsRequest,
  getTrendingTagDownloadsRequest,
  getCCULocationPlaytimeActive,
  getCreatorSubscribersRequest,
  getModsSessionLengthRequest,
  getOpenedUserReportsRequest,
  getClosedUserReportsRequest,
  getTotalTagDownloadsRequest,
  getOpenedModReportsRequest,
  getClosedModReportsRequest,
  getCreatorDownloadsRequest,
  getCreatorModsStatRequest,
  getSessionPlaytimeSummary,
  getDownloadsByTagsRequest,
  getCreatorPlayersRequest,
  getGuidesCommentsRequest,
  getCreatorJoinedRequest,
  getCCULocationPlaytime,
  getModsCommentsRequest,
  getCreatorsStatRequest,
  getMonetizationMetrics,
  getSubscriptionRequest,
  getCCUModsUsersActive,
  getUsersBannedRequest,
  getDeletedModsRequest,
  getModsUpdatedRequest,
  getModPlayersRequest,
  getSessionModSummary,
  getFilesAddedRequest,
  getUserJoinedRequest,
  getModsAddedRequest,
  getDownloadsRequest,
  getBandwidthRequest,
  getAuthUsersRequest,
  getPlatformPlaytime,
  getAllUsersRequest,
  getSessionPlatform,
  getCCUTotalSummary,
  getApiKeyRequest,
  getSessionLength,
  getCCUModSummary,
  getCCUByPlatform,
  getCCUSummary,
  getCCUPeak,
} from '@services'

const storage = ref({})
const prevResource = ref(null)

// Stores comparison for scatter chart
const userStorageByDates = ref({})

const stubAsync = {
  loading: ref(false),
  error: ref(''),
  data: ref({}),
  run: () => ({}),
}

export default function (settings) {
  const { gameNameId, modNameId, siteAdmin } = useMetricsData()
  const { getColumnTimePeriod, timePeriod, dateRange, gameRef, modRef } =
    useMetricsDates()
  const error = ref(false)
  const currentResource = modNameId
    ? modNameId
    : gameNameId
      ? gameNameId
      : 'site'
  const secondary = settings?.secondary
  // This is currently used to override only the total stats of a game.
  const gameNameIdOverride = settings?.gameNameIdOverride
  const dateRangeOverride = settings?.dateRangeOverride
  const totalSharesColumn = modNameId
    ? 'seller_total'
    : gameNameId
      ? 'store_total'
      : undefined

  clearStorage()

  /* Requests */
  // UGC stats dashboard
  const downloadAsync = getAsyncObject({
    categoryRequest: getDownloadsByCategoriesRequest,
    tagRequest: getDownloadsByTagsRequest,
    request: getDownloadsRequest,
    name: 'downloads',
    enableGeo: true,
    enablePlatform: true,
    modId: modNameId,
  })

  const subscriptionAsync = getAsyncObject({
    request: getSubscriptionRequest,
    enableGeo: true,
    enablePlatform: true,
    enableSecondary: true,
    modId: modNameId,
  })

  const modsAddedAsync = getAsyncObject({
    request: getModsAddedRequest,
  })

  const modsUpdatedAsync = getAsyncObject({
    request: getModsUpdatedRequest,
  })

  const filesAddedAsync = getAsyncObject({
    request: getFilesAddedRequest,
  })

  const bandwidthAsync = getAsyncObject({
    request: getBandwidthRequest,
    enableGeo: true,
    enablePlatform: true,
  })

  const apiKeyAsync = getAsyncObject({
    request: getApiKeyRequest,
    enableGeo: true,
    enablePlatform: true,
  })

  // User stats dashboard
  const userJoinedAsync = getAsyncObject({
    request: getUserJoinedRequest,
    enableGeo: false,
    enablePlatform: false,
  })
  const creatorJoinedAsync = getAsyncObject({
    request: getCreatorJoinedRequest,
    enableGeo: true,
    enablePlatform: true,
  })
  const modCommentsAsync = getAsyncObject({
    request: getModsCommentsRequest,
    modId: modNameId,
  })
  const guideCommentsAsync = getAsyncObject({
    request: getGuidesCommentsRequest,
  })

  const allUsersAsync = getAsyncObject({
    request: getAllUsersRequest,
    enableGeo: true,
    enablePlatform: true,
    enableSecondary: true,
    modId: modNameId,
  })

  const authUsersAsync = getAsyncObject({
    request: getAuthUsersRequest,
    enableGeo: true,
    enablePlatform: true,
    modId: modNameId,
  })

  // Health stats dashboard
  const openedModReportsAsync = getAsyncObject({
    request: getOpenedModReportsRequest,
  })

  const closedModReportsAsync = getAsyncObject({
    request: getClosedModReportsRequest,
  })

  const openedUserReportsAsync = getAsyncObject({
    request: getOpenedUserReportsRequest,
  })

  const closedUserReportsAsync = getAsyncObject({
    request: getClosedUserReportsRequest,
  })

  const deletedModsAsync = getAsyncObject({
    request: getDeletedModsRequest,
  })

  const usersBannedAsync = getAsyncObject({
    request: getUsersBannedRequest,
  })

  // Monetization stats.
  const { REFUNDED, PAID, CLEARED } = METRICS_TRANSACTION_TYPES
  const { MIO, USD } = METRICS_CURRENCY_TYPES
  const tokenPackSalesAsync = getAsyncObject({
    request: getMonetizationMetrics,
    enableGeo: false,
    enablePlatform: false,
    disableTotal: true,
    modId: modNameId,
    eventType: STANDARD_EVENT_TYPES.TRANSACTION_NET,
    filter: {
      transaction_type: PAID,
      currency_type: USD,
    },
  })

  const tokenPackRefundsAsync = getAsyncObject({
    request: getMonetizationMetrics,
    enableGeo: false,
    enablePlatform: false,
    disableTotal: true,
    modId: modNameId,
    eventType: STANDARD_EVENT_TYPES.TRANSACTION_NET,
    filter: {
      transaction_type: REFUNDED,
      currency_type: USD,
    },
  })

  const marketplaceSalesAsync = getAsyncObject({
    request: getMonetizationMetrics,
    enableGeo: false,
    enablePlatform: false,
    disableTotal: true,
    modId: modNameId,
    eventType: STANDARD_EVENT_TYPES.TRANSACTION_NET,
    filter: {
      transaction_type: PAID,
      currency_type: MIO,
    },
  })

  const marketplaceRefundsAsync = getAsyncObject({
    request: getMonetizationMetrics,
    enableGeo: false,
    enablePlatform: false,
    disableTotal: true,
    modId: modNameId,
    eventType: STANDARD_EVENT_TYPES.TRANSACTION_NET,
    filter: {
      transaction_type: REFUNDED,
      currency_type: MIO,
    },
  })

  // Overview
  const tokenTransactionTotalAsync = getUseAsyncWithArgs((start, end, column) =>
    getMonetizationMetrics({
      metricsType: METRICS_TYPES.STANDARD,
      recordType: RECORD_TYPES.TOTAL,
      gameId: gameNameId,
      modId: modNameId,
      dateFrom: start,
      dateTo: end,
      filter: {
        transaction_type: CLEARED,
        currency_type: USD,
        column,
      },
      eventType: STANDARD_EVENT_TYPES.TRANSACTION_NET,
    })
  )

  const tokenGrossAsync = getUseAsyncWithArgs((start, end) =>
    getMonetizationMetrics({
      recordType: RECORD_TYPES.TOTAL,
      gameId: gameNameId,
      modId: modNameId,
      dateFrom: start,
      dateTo: end,
      filter: {
        transaction_type: CLEARED,
        currency_type: USD,
      },
      eventType: STANDARD_EVENT_TYPES.TRANSACTION_NET,
    })
  )

  const tokenNetAsync = getUseAsyncWithArgs((start, end) =>
    getMonetizationMetrics({
      recordType: RECORD_TYPES.TOTAL,
      gameId: gameNameId,
      modId: modNameId,
      dateFrom: start,
      dateTo: end,
      filter: {
        transaction_type: CLEARED,
        currency_type: USD,
        column: totalSharesColumn,
      },
      eventType: STANDARD_EVENT_TYPES.TRANSACTION_NET,
    })
  )

  const tokenRefundAsync = getUseAsyncWithArgs((start, end) =>
    getMonetizationMetrics({
      recordType: RECORD_TYPES.TOTAL,
      gameId: gameNameId,
      modId: modNameId,
      dateFrom: start,
      dateTo: end,
      filter: {
        transaction_type: REFUNDED,
        currency_type: USD,
      },
      eventType: STANDARD_EVENT_TYPES.TRANSACTION_NET,
    })
  )

  const marketplaceTransactionTotalAsync = getUseAsyncWithArgs(
    (start, end, column) =>
      getMonetizationMetrics({
        recordType: RECORD_TYPES.TOTAL,
        gameId: gameNameId,
        modId: modNameId,
        dateFrom: start,
        dateTo: end,
        filter: {
          transaction_type: CLEARED,
          currency_type: MIO,
          column,
        },
        eventType: STANDARD_EVENT_TYPES.TRANSACTION_NET,
      })
  )

  const marketplaceGrossAsync = getUseAsyncWithArgs((start, end) =>
    getMonetizationMetrics({
      recordType: RECORD_TYPES.TOTAL,
      gameId: gameNameId,
      modId: modNameId,
      dateFrom: start,
      dateTo: end,
      filter: {
        transaction_type: CLEARED,
        currency_type: MIO,
      },
      eventType: STANDARD_EVENT_TYPES.TRANSACTION_NET,
    })
  )

  const marketplaceNetAsync = getUseAsyncWithArgs((start, end) =>
    getMonetizationMetrics({
      recordType: RECORD_TYPES.TOTAL,
      gameId: gameNameId,
      modId: modNameId,
      dateFrom: start,
      dateTo: end,
      filter: {
        transaction_type: CLEARED,
        currency_type: MIO,
        column: totalSharesColumn,
      },
      eventType: STANDARD_EVENT_TYPES.TRANSACTION_NET,
    })
  )

  const marketplaceRefundAsync = getUseAsyncWithArgs((start, end) =>
    getMonetizationMetrics({
      recordType: RECORD_TYPES.TOTAL,
      gameId: gameNameId,
      modId: modNameId,
      dateFrom: start,
      dateTo: end,
      filter: {
        transaction_type: REFUNDED,
        currency_type: MIO,
      },
      eventType: STANDARD_EVENT_TYPES.TRANSACTION_NET,
    })
  )

  // Monetization pending stats
  const tokenTransactionPendingAsync = getUseAsyncWithArgs((column) =>
    getMonetizationMetrics({
      recordType: RECORD_TYPES.TOTAL,
      gameId: gameNameId,
      modId: modNameId,
      dateFrom: 0,
      dateTo: 0,
      filter: {
        column,
        currency_type: USD,
      },
      eventType: STANDARD_EVENT_TYPES.TRANSACTION_UNCLEARED_GAME,
    })
  )

  const tokenGrossPendingAsync = getUseAsyncWithArgs((column) =>
    getMonetizationMetrics({
      recordType: RECORD_TYPES.TOTAL,
      gameId: gameNameId,
      modId: modNameId,
      dateFrom: 0,
      dateTo: 0,
      filter: {
        column,
        currency_type: USD,
      },
      eventType: STANDARD_EVENT_TYPES.TRANSACTION_UNCLEARED_GAME,
    })
  )

  const tokenNetPendingAsync = getUseAsyncWithArgs((column) =>
    getMonetizationMetrics({
      recordType: RECORD_TYPES.TOTAL,
      gameId: gameNameId,
      modId: modNameId,
      dateFrom: 0,
      dateTo: 0,
      filter: {
        column,
        currency_type: USD,
      },
      eventType: STANDARD_EVENT_TYPES.TRANSACTION_UNCLEARED_GAME,
    })
  )

  const marketplaceTransactionPendingAsync = getUseAsyncWithArgs((column) =>
    getMonetizationMetrics({
      metricsType: METRICS_TYPES.STANRDARD,
      recordType: RECORD_TYPES.TOTAL,
      gameId: gameNameId,
      modId: modNameId,
      dateFrom: 0,
      dateTo: 0,
      filter: {
        column,
        currency_type: modNameId ? undefined : MIO,
      },
      eventType: modNameId
        ? STANDARD_EVENT_TYPES.TRANSACTION_UNCLEARED_MOD
        : STANDARD_EVENT_TYPES.TRANSACTION_UNCLEARED_GAME,
    })
  )

  const marketplaceGrossPendingAsync = getUseAsyncWithArgs((column) =>
    getMonetizationMetrics({
      recordType: RECORD_TYPES.TOTAL,
      gameId: gameNameId,
      modId: modNameId,
      dateFrom: 0,
      dateTo: 0,
      filter: {
        column,
        currency_type: modNameId ? undefined : MIO,
      },
      eventType: modNameId
        ? STANDARD_EVENT_TYPES.TRANSACTION_UNCLEARED_MOD
        : STANDARD_EVENT_TYPES.TRANSACTION_UNCLEARED_GAME,
    })
  )

  const marketplaceNetPendingAsync = getUseAsyncWithArgs((column) =>
    getMonetizationMetrics({
      recordType: RECORD_TYPES.TOTAL,
      gameId: gameNameId,
      modId: modNameId,
      dateFrom: 0,
      dateTo: 0,
      filter: {
        column,
        currency_type: modNameId ? undefined : MIO,
      },
      eventType: modNameId
        ? STANDARD_EVENT_TYPES.TRANSACTION_UNCLEARED_MOD
        : STANDARD_EVENT_TYPES.TRANSACTION_UNCLEARED_GAME,
    })
  )

  // Session stats dashboard
  const sessionPlaytimeAsync = getAsyncObject({
    request: getPlatformPlaytime,
    disableTotal: true,
    enablePlatform: true,
  })

  const ccuPlatformAsync = getAsyncObject({
    request: getCCUByPlatform,
    disableTotal: true,
    enablePlatform: true,
  })

  const ccuPeakAsync = getAsyncObject({
    request: getCCUPeak,
    disableTotal: true,
    enablePlatform: true,
  })

  const getSessionLengthAsync = getUseAsyncWithArgs((start, end) =>
    getSessionLength(gameNameId, start, end)
  )

  const getSessionPlatformAsync = getUseAsyncWithArgs((start, end) =>
    getSessionPlatform(gameNameId, start, end)
  )

  const getCCUModSummaryAsync = getUseAsyncWithArgs((start, end) =>
    getCCUModSummary(gameNameId, start, end)
  )

  const getCCUSummaryAsync = getUseAsyncWithArgs((start, end) =>
    getCCUSummary(gameNameId, start, end)
  )

  const getSessionModSummaryAsync = getUseAsyncWithArgs((start, end) =>
    getSessionModSummary(gameNameId, start, end)
  )

  const getSessionPlaytimeSummaryAsync = getUseAsyncWithArgs((start, end) =>
    getSessionPlaytimeSummary(gameNameId, start, end)
  )

  const getCCULocationPlaytimeAsync = getUseAsyncWithArgs((start, end) =>
    getCCULocationPlaytime(gameNameId, start, end)
  )

  const getCCULocationPlaytimeActiveAsync = getUseAsyncWithArgs((start, end) =>
    getCCULocationPlaytimeActive(gameNameId, start, end)
  )

  const getCCUTotalSummaryAsync = getUseAsyncWithArgs(() =>
    getCCUTotalSummary(gameNameId)
  )

  const getCCUModsUsersActiveAsync = getUseAsyncWithArgs((start, end) =>
    getCCUModsUsersActive(gameNameId, start, end)
  )

  // Session page modals
  const getModPlayersAsync = getUseAsyncWithArgs((column) =>
    getModPlayersRequest(gameNameId, modNameId || settings?.modNameId, column)
  )

  const getCreatorModsStatAsync = getUseAsyncWithArgs((start, end, column) =>
    getCreatorModsStatRequest({
      gameId: gameNameId,
      modId: modNameId || settings?.modNameId,
      dateFrom: start,
      dateTo: end,
      filter: {
        column,
      },
      recordType: RECORD_TYPES.TOTAL,
    })
  )

  const getModsSessionLengthAsync = getUseAsyncWithArgs((start, end) =>
    getModsSessionLengthRequest(
      gameNameId,
      modNameId || settings?.modNameId,
      start,
      end
    )
  )

  const getTotalTagCategoryDownloadsAsync = getUseAsyncWithArgs(
    (start, end, filter) =>
      getTotalTagCategoryDownloadsRequest(gameNameId, start, end, filter)
  )

  const getTotalTagDownloadsAsync = getUseAsyncWithArgs((start, end, filter) =>
    getTotalTagDownloadsRequest(gameNameId, start, end, filter)
  )

  const getCreatorPlayersAsync = getUseAsyncWithArgs((column) =>
    getCreatorPlayersRequest(gameNameId, settings?.creatorId, column)
  )

  const getCreatorSessionsAsync = getUseAsyncWithArgs((start, end) =>
    getCreatorModsStatRequest({
      gameId: gameNameId,
      dateFrom: start,
      dateTo: end,
      filter: {
        column: 'sessions',
        creator: settings?.creatorId,
      },
      recordType: RECORD_TYPES.TOTAL,
    })
  )

  const getCreatorsPlaytimeAsync = getUseAsyncWithArgs((start, end) =>
    getCreatorsStatRequest({
      gameId: gameNameId,
      dateFrom: start,
      dateTo: end,
      filter: {
        column: 'playtime',
        creator: settings?.creatorId,
      },
      recordType: RECORD_TYPES.TOTAL,
    })
  )

  const getTopCreatorModsPlaytimeAsync = getUseAsyncWithArgs((start, end) =>
    getCreatorModsStatRequest({
      gameId: gameNameId,
      dateFrom: start,
      dateTo: end,
      filter: {
        column: 'playtime',
        creator: settings?.creatorId,
      },
      recordType: RECORD_TYPES.TRENDING,
    })
  )

  // Trending record type.
  function getTrendingModDownloadsAsync(start, end, filter) {
    return getTrendingAsync(getTrendingModDownloadsRequest, start, end, filter)
  }

  function getTrendingCreatorDownloadsAsync(start, end, filter) {
    return getTrendingAsync(
      getTrendingCreatorDownloadsRequest,
      start,
      end,
      filter
    )
  }

  function getTrendingCreatorModsPlaytimeAsync(start, end) {
    return getTrendingAsync(getCreatorModsStatRequest, start, end, {
      column: 'playtime',
    })
  }

  function getTrendingCreatorsPlaytimeAsync(start, end) {
    return getTrendingAsync(getCreatorsStatRequest, start, end, {
      column: 'playtime',
      creator: settings?.creatorId,
    })
  }

  function getTrendingTagDownloadsAsync(start, end, filter) {
    return getTrendingAsync(getTrendingTagDownloadsRequest, start, end, filter)
  }

  function getTrendingTagDownloadsByCreatorsAsync(start, end, filter) {
    return getTrendingAsync(
      getTrendingTagDownloadsByCreatorsRequest,
      start,
      end,
      filter
    )
  }

  // Creator stats page.
  const creatorDownloadsAsync = getAsyncObject({
    request: getCreatorDownloadsRequest,
    name: 'downloads',
    disableTotal: true,
    enableGeo: false,
    enablePlatform: true,
    modId: modNameId,
    filter: { creator: settings?.creatorId },
  })

  const creatorSubscribersAsync = getAsyncObject({
    request: getCreatorSubscribersRequest,
    name: 'downloads',
    disableTotal: true,
    enableGeo: false,
    enablePlatform: true,
    modId: modNameId,
    filter: { creator: settings?.creatorId },
  })

  const creatorDownloadsLocationAsync = getUseAsyncWithArgs((range) =>
    getCreatorCountryDownloadsRequest({
      gameId: gameNameId,
      range: range,
      filter: { creator: settings?.creatorId },
    })
  )

  /* Helpers */
  // All requests in metrics should use this function to handle errors.
  async function handleAsync(asyncInfo, ...args) {
    if (METRICS_DISABLED) return

    await asyncInfo.run.apply(null, args)
    if (asyncInfo.error.value) {
      error.value = true
    }
  }

  // This injects a get into the async object that can call handleAsync with some args at the call site.
  function getUseAsyncWithArgs(cb) {
    const asyncObj = useAsync(cb)

    if (!METRICS_DISABLED)
      asyncObj.get = (...args) => handleAsync(asyncObj, ...args)
    else asyncObj.get = () => {}

    return asyncObj
  }

  // For retrieving different record types at once, e.g. the standard stats page.
  function getAsyncObject({
    categoryRequest,
    enableSecondary,
    enablePlatform,
    disableTotal,
    tagRequest,
    enableGeo,
    eventType,
    request,
    groupBy,
    filter,
    modId,
  }) {
    const _dateRange = dateRangeOverride ?? dateRange
    const asyncInfo = {
      total: !disableTotal
        ? useAsync(() =>
            request({
              gameId: gameNameIdOverride ?? gameNameId,
              dateFrom: _dateRange.value.startDate,
              dateTo: _dateRange.value.endDate,
              recordType: RECORD_TYPES.TOTAL,
              modId: modId,
              site: siteAdmin && !gameNameIdOverride,
            })
          )
        : stubAsync,
      monthly: enablePlatform
        ? useAsync(() =>
            request({
              gameId: gameNameId,
              dateFrom: dateRange.value.startDate.startOf('month'),
              dateTo: dateRange.value.endDate,
              eventType,
              groupBy: GROUP_BY_OPTIONS.PLATFORM,
              recordType: RECORD_TYPES.MONTHLY,
              filter,
              modId: modId,
              site: siteAdmin,
            })
          )
        : useAsync(() =>
            request({
              gameId: gameNameId,
              dateFrom: dateRange.value.startDate.startOf('month'),
              dateTo: dateRange.value.endDate,
              recordType: RECORD_TYPES.MONTHLY,
              eventType,
              groupBy,
              filter,
              modId: modId,
              site: siteAdmin,
            })
          ),
      daily: enablePlatform
        ? useAsync(() =>
            request({
              gameId: gameNameId,
              dateFrom: dateRange.value.startDate,
              dateTo: dateRange.value.endDate,
              eventType,
              groupBy: GROUP_BY_OPTIONS.PLATFORM,
              recordType: RECORD_TYPES.DAILY,
              filter,
              modId: modId,
              site: siteAdmin,
            })
          )
        : useAsync(() =>
            request({
              gameId: gameNameId,
              dateFrom: dateRange.value.startDate,
              dateTo: dateRange.value.endDate,
              recordType: RECORD_TYPES.DAILY,
              eventType,
              groupBy,
              filter,
              modId: modId,
              site: siteAdmin,
            })
          ),
      geo: enableGeo
        ? useAsync(() =>
            request({
              gameId: gameNameId,
              dateFrom: dateRange.value.startDate,
              dateTo: dateRange.value.endDate,
              eventType,
              groupBy: GROUP_BY_OPTIONS.LOCATION,
              recordType: RECORD_TYPES.TOTAL,
              filter,
              modId: modId,
              site: siteAdmin,
            })
          )
        : stubAsync,
      dailySecondaryOne:
        secondary?.one && enableSecondary
          ? useAsync(() =>
              secondary.one.request({
                gameId: gameNameId,
                dateFrom: dateRange.value.startDate,
                dateTo: dateRange.value.endDate,
                recordType: RECORD_TYPES.DAILY,
                modId: modId,
                site: siteAdmin,
                ...secondary.one.requestParams,
              })
            )
          : stubAsync,
      dailySecondaryTwo:
        secondary?.two && enableSecondary
          ? useAsync(() =>
              secondary.two.request({
                gameId: gameNameId,
                dateFrom: dateRange.value.startDate,
                dateTo: dateRange.value.endDate,
                recordType: RECORD_TYPES.DAILY,
                modId: modId,
                site: siteAdmin,
                ...secondary.two.requestParams,
              })
            )
          : stubAsync,
      category: categoryRequest
        ? useAsync((filter, recordType) =>
            categoryRequest({
              gameId: gameNameId,
              dateFrom: dateRange.value.startDate,
              dateTo: dateRange.value.endDate,
              recordType,
              filter,
            })
          )
        : stubAsync,
      tag: tagRequest
        ? useAsync((filter, recordType) =>
            tagRequest({
              gameId: gameNameId,
              dateFrom: dateRange.value.startDate,
              dateTo: dateRange.value.endDate,
              recordType,
              filter,
            })
          )
        : stubAsync,
    }

    asyncInfo.total.get = () => handleAsync(asyncInfo.total)
    asyncInfo.monthly.get = () => handleAsync(asyncInfo.monthly)
    asyncInfo.daily.get = () => handleAsync(asyncInfo.daily)
    asyncInfo.geo.get = () => handleAsync(asyncInfo.geo)

    asyncInfo.category.get = (filter, recordType) =>
      handleAsync(asyncInfo.category, filter, recordType)
    asyncInfo.tag.get = (filter, recordType) =>
      handleAsync(asyncInfo.tag, filter, recordType)

    // secondary stats used by select dashboards
    if (secondary?.one && enableSecondary)
      asyncInfo.dailySecondaryOne.get = () =>
        handleAsync(asyncInfo.dailySecondaryOne)
    else asyncInfo.dailySecondaryOne.get = () => {}

    if (secondary?.two && enableSecondary)
      asyncInfo.dailySecondaryTwo.get = () =>
        handleAsync(asyncInfo.dailySecondaryTwo)
    else asyncInfo.dailySecondaryTwo.get = () => {}

    return asyncInfo
  }

  // Fetched data are naively stored per chart per time period for a dashboard
  // Except for custom period data that is still re-fetched every time
  function getStorage(type, tabName, customKey) {
    if (customKey) {
      return storage.value[tabName]?.[type]?.[customKey]
    }
    return storage.value[tabName]?.[timePeriod.value]?.[type]
  }

  function setStorage(type, data, tabName, customKey) {
    if (customKey) {
      const customData = {
        [type]: {
          ...storage.value[tabName]?.[type],
          [customKey]: {
            ...clone(data),
          },
        },
      }
      storage.value[tabName] = customData
      return
    }

    const _timePeriod = timePeriod.value
    if (!storage.value[tabName]) storage.value[tabName] = {}
    if (!storage.value[tabName][_timePeriod])
      storage.value[tabName][_timePeriod] = {}

    storage.value[tabName][_timePeriod][type] = clone(data)
  }

  function clearStorage() {
    if (currentResource != prevResource.value) {
      gameRef.value = undefined
      modRef.value = undefined

      storage.value = {}
      userStorageByDates.value = {}
      prevResource.value = currentResource
    }
  }

  // Handle trending requests.
  function getTrendingAsync(request, start, end, filter) {
    const requestParams = {
      gameId: gameNameId,
      dateFrom: start,
      dateTo: end,
      modId: modNameId,
      site: siteAdmin,
      recordType: RECORD_TYPES.TRENDING,
      filter,
    }

    const asyncInfo = useAsync(() => request(requestParams))
    asyncInfo.get = () => handleAsync(asyncInfo)

    return asyncInfo
  }

  // The asyncRequest object need to have 'getAsync' which has a get(), and a 'state' member to hold the timeperiods data
  // This is useful to handle storing states for stats that spans multiple time periods, so requests don't get re-send if user goes back and forth between all the time options.
  async function fetchDataIntoState(timePeriod, asyncRequest, start, end) {
    if (timePeriod > 0 && !asyncRequest.state.value[timePeriod]) {
      if (asyncRequest.noTimeStamp) {
        await asyncRequest.getAsync.get(
          getColumnTimePeriod(
            timePeriod,
            asyncRequest.column,
            asyncRequest.range
          )
        )
      } else {
        if (!start || !end) return
        await asyncRequest.getAsync.get(start, end, asyncRequest.column)
      }

      asyncRequest.state.value[timePeriod] = asyncRequest.getAsync.data.value
    }
  }

  async function fetchPendingIntoState(timePeriod, asyncRequest) {
    await asyncRequest.getAsync.get(
      getColumnTimePeriod(timePeriod, asyncRequest.column)
    )
    asyncRequest.state.value[timePeriod] = asyncRequest.getAsync.data.value
  }

  watch(error, (_error) => {
    if (_error) {
      addToast({
        title: 'Failed to retrieve complete stats data',
        isError: true,
        text: 'An unexpected error has occurred while retrieving stats, some data may be missing. Please try again later.',
      })
    }
  })

  return {
    getTrendingTagDownloadsByCreatorsAsync,
    getTotalTagCategoryDownloadsAsync,
    getTrendingCreatorDownloadsAsync,
    getTrendingTagDownloadsAsync,
    getTrendingModDownloadsAsync,
    getTotalTagDownloadsAsync,
    openedUserReportsAsync,
    closedUserReportsAsync,
    closedModReportsAsync,
    openedModReportsAsync,
    fetchPendingIntoState,
    userStorageByDates,
    guideCommentsAsync,
    creatorJoinedAsync,
    fetchDataIntoState,
    subscriptionAsync,
    usersBannedAsync,
    deletedModsAsync,
    modsUpdatedAsync,
    modCommentsAsync,
    userJoinedAsync,
    filesAddedAsync,
    bandwidthAsync,
    modsAddedAsync,
    authUsersAsync,
    downloadAsync,
    allUsersAsync,
    apiKeyAsync,
    // CCU/Session page
    getTrendingCreatorModsPlaytimeAsync,
    getCCULocationPlaytimeActiveAsync,
    getTrendingCreatorsPlaytimeAsync,
    getSessionPlaytimeSummaryAsync,
    getTopCreatorModsPlaytimeAsync,
    getCCULocationPlaytimeAsync,
    getCCUModsUsersActiveAsync,
    getSessionModSummaryAsync,
    getSessionPlatformAsync,
    getCCUTotalSummaryAsync,
    getCCUModSummaryAsync,
    getSessionLengthAsync,
    sessionPlaytimeAsync,
    getCCUSummaryAsync,
    ccuPlatformAsync,
    ccuPeakAsync,
    // CCU/Session modal
    getModsSessionLengthAsync,
    getCreatorsPlaytimeAsync,
    getCreatorModsStatAsync,
    getCreatorSessionsAsync,
    getCreatorPlayersAsync,
    getModPlayersAsync,
    // Monetization overview
    marketplaceTransactionTotalAsync,
    tokenTransactionTotalAsync,
    marketplaceRefundAsync,
    marketplaceGrossAsync,
    marketplaceNetAsync,
    tokenRefundAsync,
    tokenGrossAsync,
    tokenNetAsync,
    // Monetization trends
    marketplaceRefundsAsync,
    marketplaceSalesAsync,
    tokenPackRefundsAsync,
    tokenPackSalesAsync,
    // Monetization pending overview
    marketplaceTransactionPendingAsync,
    marketplaceGrossPendingAsync,
    tokenTransactionPendingAsync,
    marketplaceNetPendingAsync,
    tokenGrossPendingAsync,
    tokenNetPendingAsync,
    // Creator stats page.
    creatorDownloadsLocationAsync,
    creatorSubscribersAsync,
    creatorDownloadsAsync,
    // Misc
    getStorage,
    setStorage,
    error,
  }
}
