<template>
  <!-- danger -->
  <div class="base-icon" v-html="require(`../../icons/${icon}.svg?raw`)" />
</template>
<script>
export default {
  props: {
    icon: {
      type: String,
      default: '',
    },
  },
}
</script>
<style scoped>
.base-icon > :deep(svg) {
  width: 100%;
  height: 100%;
}
</style>
