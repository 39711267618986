<template>
  <loading-checkbox
    v-if="inputLoading"
    :label="topLabel"
    :background="background"
    :alt-bg="altBg"
  />

  <div v-else class="tw-flex tw-flex-col" :class="{ 'tw-w-full': optionList }">
    <base-input-label
      v-if="topLabel || tip"
      margin-bottom
      :id-for="inputId"
      :required="required"
      :label="topLabel"
      :tip="tip"
      :tip-hover="tipHover"
      :label-between="labelBetween"
    />
    <label
      class="tw-flex tw-items-center tw-group tw-button-transition tw-relative tw-font-medium"
      :class="[
        customLabelStyles,
        inputBgStyles,
        errorStyles,
        disabled ? 'tw-cursor-not-allowed tw-opacity-50' : 'tw-cursor-pointer',
        {
          'tw-input--height-small': small,
          'tw-input--height-large': !small && !filter,
          'tw-input--px tw-input--min-height': background,
          'tw-justify-center tw-w-full tw-input--px': optionList,
          'tw-capitalize': capsCase,
          'tw-global--border-radius': !noRadius,
        },
      ]"
      @keydown="handleKeyPress"
    >
      <!-- hidden input is used for tabing / focus -->
      <input
        :id="inputId"
        class="tw-absolute tw-opacity-0 tw-size-0"
        :checked="checked"
        type="checkbox"
        :disabled="readonly"
        :tabindex="disabled ? '-1' : '0'"
        @click="updateCheckbox"
      />
      <span
        :data-testid="UI_TEST_ENVS.includes(MODIO_ENV) ? testId : ''"
        class="tw-flex tw-items-center tw-justify-center tw-shrink-0 tw-button-transition"
        :class="[
          checkStyles,
          hasHover,
          optionList
            ? 'tw-absolute tw-top-0 tw-left-0 tw-size-full tw-global--border-radius'
            : 'tw-size-5 tw-rounded tw-mr-3 last:tw-mr-0',
        ]"
      >
        <font-awesome-icon
          v-if="checked && !optionList"
          icon="check"
          class="tw-size-4"
        />
      </span>

      <slot v-if="$slots.label" name="label" :for-id="inputId" />
      <span v-else-if="capsCase" :class="{ 'tw-z-1': optionList }">{{
        label.toLowerCase()
      }}</span>
      <span v-else-if="label" :class="{ 'tw-z-1': optionList }">{{
        label
      }}</span>

      <span
        v-if="append !== undefined"
        class="tw-ml-auto tw-pl-1 tw-text-right tw-opacity-50"
      >
        {{ append }}
      </span>
    </label>

    <div
      v-if="hasErrors || hint"
      class="tw-flex tw-flex-col tw-space-y-1"
      :class="errorGap ? 'tw-hint-gap' : 'tw-mb-2'"
    >
      <base-input-errors :errors="errors" align="left" />

      <base-input-hint v-if="hint" :id="`${inputId}Help`">
        {{ hint }}
      </base-input-hint>
    </div>
  </div>
</template>

<script>
import LoadingCheckbox from '@components/Loading/LoadingCheckbox.vue'
import { MODIO_ENV, UI_TEST_ENVS } from '@config'
import { computed, toRefs, inject } from 'vue'
import { genHtmlId } from '@helpers/utils.js'

export default {
  components: { LoadingCheckbox },
  props: {
    id: {
      type: [String, Number],
      default: null,
    },
    modelValue: {
      type: [Boolean, Number],
      default: undefined,
    },
    append: {
      type: [String, Number],
      default: undefined,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
    topLabel: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    tip: {
      type: String,
      default: '',
    },
    tipHover: {
      type: Boolean,
      default: false,
    },
    labelBetween: {
      type: Boolean,
      default: true,
    },
    hint: {
      type: String,
      default: '',
    },
    errors: {
      type: Array,
      default: () => [],
    },
    icon: {
      type: String,
      default: null,
    },
    background: {
      type: Boolean,
      default: true,
    },
    inputLoading: {
      type: Boolean,
      default: false,
    },
    small: {
      type: Boolean,
      default: false,
    },
    capsCase: {
      type: Boolean,
      default: false,
    },
    optionList: {
      type: Boolean,
      default: false,
    },
    altBg: {
      type: Boolean,
      default: false,
    },
    darkBg: {
      type: Boolean,
      default: false,
    },
    filter: {
      type: Boolean,
      default: false,
    },
    noRadius: {
      type: Boolean,
      default: false,
    },
    customLabelStyles: {
      type: String,
      default: '',
    },
    errorGap: {
      type: Boolean,
      default: true,
    },
    testId: {
      type: String,
      default: '',
    },
  },
  emits: ['input'],
  setup(props, { emit }) {
    const {
      background,
      modelValue,
      optionList,
      disabled,
      filter,
      darkBg,
      errors,
      altBg,
      id,
    } = toRefs(props)

    const inputId = id.value ? id.value : genHtmlId()
    const readonly = inject('saving', false)

    const checked = computed(
      () => modelValue.value === 1 || modelValue.value === true
    )

    const hasErrors = computed(() => !!errors.value?.length)

    const errorStyles = computed(() => {
      if (hasErrors.value) {
        return background.value
          ? 'tw-border-danger tw-border-2'
          : 'tw-text-danger'
      }
      return background.value && !disabled.value ? 'tw-input--focus-within' : ''
    })

    const hasHover = computed(() => {
      if (disabled.value || checked.value) {
        return ''
      }

      if (altBg.value) {
        return background.value ? '' : 'tw-bg-theme--group-hover'
      } else if (darkBg.value) {
        return background.value ? '' : 'tw-bg-theme-3--group-hover'
      } else if (filter.value) {
        return ''
      } else {
        return background.value ? '' : 'tw-bg-theme-2--group-hover'
      }
    })

    const inputBgStyles = computed(() => {
      return background.value
        ? {
            'tw-bg-input-hover': !disabled.value,
            'tw-bg-theme-1': disabled.value,
          }
        : {
            'tw-opacity-90 hover:tw-opacity-100 focus-within:tw-opacity-100':
              !disabled.value,
            'focus-within:tw-text-primary':
              !optionList.value && !disabled.value,
          }
    })

    const checkStyles = computed(() => {
      if (checked.value) {
        return 'tw-bg-primary tw-text-primary-text'
      }
      if (altBg.value) {
        return background.value
          ? ''
          : 'tw-bg-theme tw-opacity-40 group-hover:tw-opacity-100 group-focus:tw-opacity-100'
      } else if (darkBg.value) {
        return background.value
          ? ''
          : 'tw-bg-theme-3 tw-opacity-70 group-hover:tw-opacity-100 group-focus:tw-opacity-100'
      } else if (filter.value) {
        return background.value
          ? 'tw-bg-theme'
          : 'tw-bg-theme-text tw-opacity-20 group-hover:tw-opacity-50 group-focus:tw-opacity-50'
      } else {
        return background.value ? 'tw-bg-theme' : 'tw-bg-theme-1'
      }
    })

    function updateCheckbox() {
      if (!disabled.value) {
        emit('input', modelValue.value ? 0 : 1)
      }
    }

    function handleKeyPress(e) {
      switch (e.keyCode) {
        case 9: //tab
          break
        case 13: //return
        case 32: //space
          e.preventDefault()
          updateCheckbox()

          break
      }
    }

    return {
      handleKeyPress,
      updateCheckbox,
      inputBgStyles,
      UI_TEST_ENVS,
      checkStyles,
      errorStyles,
      MODIO_ENV,
      hasErrors,
      readonly,
      hasHover,
      checked,
      inputId,
    }
  },
}
</script>
