import { ref, readonly } from 'vue'

export default function () {
  const checkedIdsState = ref([])

  function toggleChecked(id) {
    const ids = [...checkedIdsState.value]
    const index = ids.indexOf(id)
    if (index >= 0) ids.splice(index, 1)
    else ids.push(id)

    checkedIdsState.value = ids
  }

  function clearCheckedIds() {
    checkedIdsState.value = []
  }

  function setCheckedIds(val) {
    checkedIdsState.value = val
  }

  return {
    checkedIds: readonly(checkedIdsState),
    clearCheckedIds,
    setCheckedIds,
    toggleChecked,
  }
}
