import { THEME_DEFAULT } from '@config/colorsTheme.js'
import * as routes from '@config/routeNames.js'
import { useSuggestions } from '@composables'
import * as seo from '@config/seo.js'
import {
  DASHBOARD_REPORT_TYPES,
  RESOURCE_GAMES,
  RESOURCE_MODS,
} from '@config/options.js'

const { handleSuggestion } = useSuggestions()
export const loggedIn = [
  {
    path: '/g/add/',
    name: routes.GAME_ADD_ROUTE,
    meta: {
      seo: [
        {
          tag: 'title',
          content: seo.GAME_ADD_ROUTE_TITLE,
        },
        {
          tag: 'meta',
          name: { attribute: 'name', value: 'description' },
          content: seo.GAME_ADD_ROUTE_DESCRIPTION_META,
        },
      ],
      breadcrumb: {
        head: { label: 'Games', to: { name: routes.GAME_BROWSE_ROUTE } },
        tail: { label: 'Add', to: { name: routes.GAME_ADD_ROUTE } },
      },
    },
    component: () =>
      import(/* webpackChunkName: 'game-add' */ '@views/GameAdd.vue'),
  },
  {
    path: '/g/:game/admin/',
    redirect: { name: routes.GAME_ADMIN_SETTINGS_ROUTE },
    meta: {
      seo: [
        {
          tag: 'title',
          dynamicContent: ({ game }) =>
            seo.GAME_ADMIN_ROUTE_TITLE.replace(':game-name', game.name),
        },
        {
          tag: 'meta',
          name: { attribute: 'name', value: 'description' },
          dynamicContent: ({ game }) => game.summary || '',
        },
      ],
      error: [
        {
          status: 404,
          errorRef: 14000,
          method: 'get',
          action: (_, error) => {
            handleSuggestion(error)
          },
        },
        {
          status: 403,
          errorRef: 14002,
          method: 'get',
          action: (_, error) => {
            handleSuggestion(error)
          },
        },
        {
          status: 403,
          errorRef: 14007,
          method: 'get',
          action: (_, error) => {
            handleSuggestion(error)
          },
        },
      ],
    },
    component: () =>
      import(/* webpackChunkName: 'game-admin' */ '@views/GameAdmin.vue'),
    children: [
      {
        path: 'settings',
        name: routes.GAME_ADMIN_SETTINGS_ROUTE,
        component: () =>
          import(
            /* webpackChunkName: 'game-admin' */ '@components/Game/Settings.vue'
          ),
      },
      {
        path: 'moderation-rules',
        name: routes.GAME_ADMIN_MODERATION_ROUTE,
        component: () =>
          import(
            /* webpackChunkName: 'game-admin' */ '@views/GamePermissionsForm.vue'
          ),
      },
      {
        path: 'monetization',
        redirect: { name: routes.GAME_ADMIN_MONETIZATION_HOW_ROUTE },
      },
      {
        path: 'monetization/how-it-works',
        name: routes.GAME_ADMIN_MONETIZATION_HOW_ROUTE,
        meta: {
          scope: ['global:monetization'],
        },
        component: () =>
          import(
            /* webpackChunkName: 'game-monetization' */ '@components/Monetization/Game/HowItWorks.vue'
          ),
      },
      {
        path: 'monetization/get-started',
        name: routes.GAME_ADMIN_MONETIZATION_STARTED_ROUTE,
        meta: {
          scope: ['game:monetization', 'game:notonboarded', 'game:leaderonly'],
        },
        component: () =>
          import(
            /* webpackChunkName: 'game-monetization' */ '@components/Monetization/Game/GetStarted/GetStarted.vue'
          ),
      },
      {
        path: 'monetization/get-started-business',
        name: routes.GAME_ADMIN_MONETIZATION_STARTED_BUSINESS_ROUTE,
        meta: {
          scope: [
            'global:monetization',
            'game:monetization',
            'game:notonboarded',
            'game:leader',
          ],
        },
        component: () =>
          import(
            /* webpackChunkName: 'game-monetization' */ '@components/Monetization/Game/GetStarted/Business/Steps.vue'
          ),
      },
      {
        path: 'monetization/get-started-individual',
        name: routes.GAME_ADMIN_MONETIZATION_STARTED_INDIVIDUAL_ROUTE,
        meta: {
          scope: [
            'global:monetization',
            'game:monetization',
            'game:notonboarded',
            'game:leader',
          ],
        },
        component: () =>
          import(
            /* webpackChunkName: 'game-monetization' */ '@components/Monetization/Game/GetStarted/Individual/Steps.vue'
          ),
      },
      {
        path: 'monetization/summary',
        name: routes.GAME_ADMIN_MONETIZATION_SUMMARY_ROUTE,
        meta: {
          scope: [
            'global:monetization',
            'game:monetization',
            'game:notleaderonly',
            ['or', 'game:kyc', 'game:notrevenueshare'],
          ],
        },
        component: () =>
          import(
            /* webpackChunkName: 'game-monetization' */ '@components/Monetization/Game/Summary.vue'
          ),
      },
      {
        path: 'monetization/dashboard',
        name: routes.GAME_ADMIN_MONETIZATION_ROUTE,
        meta: {
          scope: [
            'global:monetization',
            'game:monetization',
            'game:onboarded',
            [
              'or',
              'user:onboarded',
              'site:admin',
              ['and', 'game:kyb', 'game:revenueshare'],
            ],
            ['or', 'game:leader', ['and', 'game:kyb', 'game:revenueshare']],
          ],
        },
        props: { resource: RESOURCE_GAMES },
        component: () =>
          import(
            /* webpackChunkName: 'game-monetization' */ '@components/Monetization/Shared/Monetization.vue'
          ),
      },
      {
        path: 'monetization/settings',
        name: routes.GAME_ADMIN_MONETIZATION_SETTINGS_ROUTE,
        meta: {
          scope: [
            'global:monetization',
            'game:monetization',
            'game:onboarded',
            [
              'or',
              'user:onboarded',
              'site:admin',
              ['and', 'game:kyb', 'game:revenueshare'],
            ],
            ['or', 'game:leader', ['and', 'game:kyb', 'game:revenueshare']],
          ],
        },
        component: () =>
          import(
            /* webpackChunkName: 'game-monetization' */ '@components/Monetization/Game/Settings.vue'
          ),
      },
      {
        path: 'monetization/transactions',
        name: routes.GAME_ADMIN_MONETIZATION_TRANSACTIONS_ROUTE,
        meta: {
          scope: [
            'global:monetization',
            'game:monetization',
            'game:onboarded',
            [
              'or',
              'user:onboarded',
              'site:admin',
              ['and', 'game:kyb', 'game:revenueshare'],
            ],
            ['or', 'game:leader', ['and', 'game:kyb', 'game:revenueshare']],
          ],
        },
        component: () =>
          import(
            /* webpackChunkName: 'game-monetization' */ '@components/Monetization/Game/TransactionsPage.vue'
          ),
      },
      {
        path: 'monetization/entity-team',
        name: routes.GAME_ADMIN_MONETIZATION_ENTITY_TEAM_ROUTE,
        meta: {
          scope: [
            'global:monetization',
            'game:monetization',
            'game:onboarded',
            'game:kyb',
            ['or', 'game:leader', 'game:revenueshare'],
          ],
        },
        component: () =>
          import(
            /* webpackChunkName: 'game-monetization' */ '@components/Monetization/Game/EntityTeam.vue'
          ),
      },
      {
        path: 'monetization/revenue-share',
        name: routes.GAME_ADMIN_MONETIZATION_REVENUE_ROUTE,
        meta: {
          scope: [
            'global:monetization',
            'game:monetization',
            'game:onboarded',
            ['or', 'user:onboarded', 'site:admin'],
            'game:kyc',
            'game:leader',
          ],
        },
        component: () =>
          import(
            /* webpackChunkName: 'game-monetization' */ '@components/Monetization/Game/RevenueShare.vue'
          ),
      },
      {
        path: 'team',
        name: routes.GAME_ADMIN_TEAM_ROUTE,
        component: () =>
          import(
            /* webpackChunkName: 'game-admin' */ '@components/Game/GameTeam.vue'
          ),
      },
      {
        path: 'mods',
        name: routes.GAME_ADMIN_MOD_DASHBOARD_ROUTE,
        component: () =>
          import(
            /* webpackChunkName: 'game-admin' */ '@components/Game/ModsDashboard.vue'
          ),
      },
      {
        path: 'guides',
        name: routes.GAME_ADMIN_GUIDE_DASHBOARD_ROUTE,
        component: () =>
          import(
            /* webpackChunkName: 'game-admin' */ '@components/Game/GuidesDashboard.vue'
          ),
      },
      {
        path: 'reports',
        name: routes.GAME_ADMIN_REPORT_ROUTE,
        redirect: { name: routes.GAME_ADMIN_REPORT_MODS_ROUTE },
      },
      {
        path: 'reports-mods',
        name: routes.GAME_ADMIN_REPORT_MODS_ROUTE,
        props: {
          resourceType: DASHBOARD_REPORT_TYPES.MODS,
        },
        component: () =>
          import(
            /* webpackChunkName: 'game-admin' */ '@components/Game/ReportsDashboard.vue'
          ),
      },
      {
        path: 'reports-guides',
        name: routes.GAME_ADMIN_REPORT_GUIDES_ROUTE,
        props: {
          resourceType: DASHBOARD_REPORT_TYPES.GUIDES,
        },
        component: () =>
          import(
            /* webpackChunkName: 'game-admin' */ '@components/Game/ReportsDashboard.vue'
          ),
      },
      {
        path: 'reports-users',
        name: routes.GAME_ADMIN_REPORT_USERS_ROUTE,
        props: {
          resourceType: DASHBOARD_REPORT_TYPES.USERS,
        },
        component: () =>
          import(
            /* webpackChunkName: 'game-admin' */ '@components/Game/ReportsDashboard.vue'
          ),
      },
      {
        path: 'users',
        name: routes.GAME_ADMIN_USER_DASHBOARD_ROUTE,
        component: () =>
          import(
            /* webpackChunkName: 'game-admin' */ '@components/Game/UserDashboard.vue'
          ),
      },
      {
        path: 'marketplace-queue',
        name: routes.GAME_ADMIN_MARKETPLACE_QUEUE_ROUTE,
        meta: {
          scope: [
            'global:monetization',
            'game:monetization',
            'game:marketplace',
            'game:onboarded',
            'game:queue',
          ],
        },
        component: () =>
          import(
            /* webpackChunkName: 'game-admin' */ '@components/Game/MarketplaceQueue.vue'
          ),
      },
      {
        path: 'partners',
        name: routes.GAME_ADMIN_PARTNERS_ROUTE,
        meta: {
          scope: [
            'global:monetization',
            'game:monetization',
            'game:onboarded',
            'game:partner',
          ],
        },
        component: () =>
          import(
            /* webpackChunkName: 'game-monetization' */ '@components/Game/Partners.vue'
          ),
      },
      {
        path: 'stats',
        children: [
          {
            path: '',
            name: routes.GAME_ADMIN_STATS_ROUTE,
            component: () =>
              import(
                /* webpackChunkName: 'game-admin' */ '@components/Metrics/Stats.vue'
              ),
          },
          {
            path: 'users',
            name: routes.GAME_ADMIN_STATS_USER_ROUTE,
            component: () =>
              import(
                /* webpackChunkName: 'game-admin' */ '@components/Metrics/Stats.vue'
              ),
          },
          {
            path: 'health',
            name: routes.GAME_ADMIN_STATS_HEALTH_ROUTE,
            component: () =>
              import(
                /* webpackChunkName: 'game-admin' */ '@components/Metrics/Stats.vue'
              ),
          },
          {
            path: 'session',
            name: routes.GAME_ADMIN_STATS_SESSION_ROUTE,
            component: () =>
              import(
                /* webpackChunkName: 'game-admin' */ '@components/Metrics/Stats.vue'
              ),
          },
        ],
      },
      {
        path: 'api-key',
        name: routes.GAME_ADMIN_APIKEY_ROUTE,
        component: () =>
          import(
            /* webpackChunkName: 'game-admin' */ '@components/Game/ApiKey.vue'
          ),
      },
      {
        path: 'embeddable-hub',
        name: routes.GAME_ADMIN_EMH_ROUTE,
        component: () =>
          import(
            /* webpackChunkName: 'game-admin' */ '@components/Game/EmbeddableHub.vue'
          ),
      },
      {
        path: 'preview',
        name: routes.GAME_ADMIN_PREVIEW_ROUTE,
        component: () =>
          import(
            /* webpackChunkName: 'game-admin' */ '@components/Game/Preview.vue'
          ),
      },
    ],
  },
  {
    path: '/g/:game/m/:mod/admin',
    redirect: { name: routes.MOD_ADMIN_SETTINGS_ROUTE },
    meta: {
      seo: [
        {
          tag: 'title',
          dynamicContent: ({ game, mod }) =>
            seo.MOD_ADMIN_ROUTE_TITLE.replace(':mod-name', mod.name).replace(
              ':game-name',
              game.name
            ),
        },
        {
          tag: 'meta',
          name: { attribute: 'name', value: 'description' },
          dynamicContent: ({ mod }) => mod.summary,
        },
      ],
      error: [
        {
          status: '*',
          errorRef: '*',
          method: 'get',
          action: (_, error) => {
            handleSuggestion(error)
          },
        },
      ],
    },
    component: () =>
      import(/* webpackChunkName: 'mod-admin' */ '@views/ModAdmin.vue'),
    children: [
      {
        path: 'settings',
        name: routes.MOD_ADMIN_SETTINGS_ROUTE,
        meta: {
          seo: [
            {
              tag: 'title',
              dynamicContent: ({ game, mod }) =>
                seo.MOD_ADMIN_ROUTE_TITLE.replace(
                  ':mod-name',
                  mod.name
                ).replace(':game-name', game.name),
            },
            {
              tag: 'meta',
              name: { attribute: 'name', value: 'description' },
              dynamicContent: ({ mod }) => mod.summary,
            },
          ],
        },
        component: () =>
          import(
            /* webpackChunkName: 'mod-admin' */ '@components/ModAdmin/ModSettings.vue'
          ),
      },
      {
        path: 'monetization',
        redirect: { name: routes.MOD_ADMIN_MONETIZATION_HOW_ROUTE },
      },
      {
        path: 'monetization/how-it-works',
        name: routes.MOD_ADMIN_MONETIZATION_HOW_ROUTE,
        meta: {
          scope: ['global:monetization', 'game:monetization'],
        },
        component: () =>
          import(
            /* webpackChunkName: 'mod-monetization' */ '@components/Monetization/Mod/HowItWorks.vue'
          ),
      },
      {
        path: 'monetization/dashboard',
        name: routes.MOD_ADMIN_MONETIZATION_ROUTE,
        meta: {
          scope: [
            'global:monetization',
            'game:monetization',
            'mod:onboarded',
            ['or', 'mod:leaderonly', 'site:admin', 'game:teammember'],
          ],
        },
        props: { resource: RESOURCE_MODS },
        component: () =>
          import(
            /* webpackChunkName: 'mod-monetization' */ '@components/Monetization/Shared/Monetization.vue'
          ),
      },
      {
        path: 'monetization/settings',
        name: routes.MOD_ADMIN_MONETIZATION_SETTINGS_ROUTE,
        meta: {
          scope: [
            'global:monetization',
            'global:marketplace',
            'game:monetization',
            'game:marketplace',
            'mod:onboarded',
            'mod:leader',
          ],
        },
        component: () =>
          import(
            /* webpackChunkName: 'mod-monetization' */ '@components/Monetization/Mod/MarketplaceSettings.vue'
          ),
      },
      {
        path: 'monetization/transactions',
        name: routes.MOD_ADMIN_TRANSACTIONS_ROUTE,
        meta: {
          scope: [
            'global:monetization',
            'game:monetization',
            'mod:onboarded',
            'mod:leader',
          ],
        },
        component: () =>
          import(
            /* webpackChunkName: 'mod-monetization' */ '@components/Monetization/Mod/Transactions.vue'
          ),
      },
      {
        path: 'monetization/revenue-share',
        name: routes.MOD_ADMIN_MONETIZATION_REVENUE_ROUTE,
        meta: {
          scope: [
            'global:monetization',
            'game:monetization',
            'mod:onboarded',
            'mod:leader',
          ],
        },
        component: () =>
          import(
            /* webpackChunkName: 'mod-monetization' */ '@components/Monetization/Mod/RevenueShare.vue'
          ),
      },
      {
        path: 'monetization/get-started-individual',
        name: routes.MOD_ADMIN_MONETIZATION_STARTED_INDIVIDUAL_ROUTE,
        meta: {
          scope: [
            'global:monetization',
            'game:monetization',
            'game:atleastone',
            'mod:notonboarded',
            'mod:leaderonly',
          ],
        },
        component: () =>
          import(
            /* webpackChunkName: 'mod-monetization' */ '@components/Monetization/Mod/GetStarted/Individual.vue'
          ),
      },
      {
        path: 'monetization/summary',
        name: routes.MOD_ADMIN_MONETIZATION_SUMMARY_ROUTE,
        meta: {
          scope: [
            'global:monetization',
            'game:monetization',
            'mod:onboarded',
            'mod:notleader',
          ],
        },
        component: () =>
          import(
            /* webpackChunkName: 'mod-monetization' */ '@components/Monetization/Mod/Summary.vue'
          ),
      },
      {
        path: 'team',
        name: routes.MOD_ADMIN_TEAM_ROUTE,
        component: () =>
          import(
            /* webpackChunkName: 'mod-admin' */ '@components/ModAdmin/ModTeam.vue'
          ),
      },
      {
        path: 'reports',
        name: routes.MOD_ADMIN_REPORTS_ROUTE,
        component: () =>
          import(
            /* webpackChunkName: 'mod-admin' */ '@components/ModAdmin/ModReportsDashboard.vue'
          ),
      },
      {
        path: 'status-logs',
        name: routes.MOD_ADMIN_STATUS_LOGS_ROUTE,
        component: () =>
          import(
            /* webpackChunkName: 'mod-admin' */ '@components/ModAdmin/ModLogs.vue'
          ),
      },
      {
        path: 'stats',
        name: routes.MOD_ADMIN_STATS_ROUTE,
        component: () =>
          import(
            /* webpackChunkName: 'mod-admin' */ '@components/Metrics/Stats.vue'
          ),
      },
      {
        path: 'preview',
        name: routes.MOD_ADMIN_PREVIEW_ROUTE,
        component: () =>
          import(
            /* webpackChunkName: 'mod-admin' */ '@components/Game/Preview.vue'
          ),
      },
    ],
  },
  {
    path: '/g/:game/r/:guide/edit',
    name: routes.GUIDE_ADMIN_EDIT_ROUTE,
    meta: {
      seo: [
        {
          tag: 'title',
          dynamicContent: ({ guide }) =>
            seo.GUIDE_ADMIN_EDIT_ROUTE_TITLE.replace(':guide-name', guide.name),
        },
        {
          tag: 'meta',
          name: { attribute: 'name', value: 'description' },
          dynamicContent: ({ guide }) => guide.summary,
        },
      ],
      error: [
        {
          status: '*',
          errorRef: '*',
          method: 'get',
          action: (_, error) => {
            handleSuggestion(error)
          },
        },
      ],
    },
    component: () =>
      import(/* webpackChunkName: 'guide-admin' */ '@views/GuideAdmin.vue'),
  },
  {
    path: '/library',
    name: routes.MY_LIBRARY_ROUTE,
    meta: {
      breadcrumb: {
        head: { label: 'Profile', to: { name: routes.PROFILE_ROUTE } },
        tail: {
          label: 'My Library',
          to: { name: routes.MY_LIBRARY_ROUTE },
        },
      },
      seo: [{ tag: 'title', content: 'My library' }],
      theme: THEME_DEFAULT,
    },
    component: () =>
      import(
        /* webpackChunkName: 'my-content' */ '@components/Profile/Panels/Library.vue'
      ),
  },
  {
    path: '/content',
    name: routes.PROFILE_CONTENT_ROUTE,
    meta: {
      breadcrumb: {
        head: { label: 'Profile', to: { name: routes.PROFILE_ROUTE } },
        tail: { label: 'Content', to: { name: routes.PROFILE_CONTENT_ROUTE } },
      },
      seo: [{ tag: 'title', content: 'Content' }],
      theme: THEME_DEFAULT,
    },
    component: () =>
      import(
        /* webpackChunkName: 'my-content' */ '@components/Profile/Panels/Content.vue'
      ),
  },
  {
    path: '/me',
    name: routes.PROFILE_ROUTE,
    redirect: { name: routes.PROFILE_ACCOUNT_ROUTE },
    meta: {
      seo: [
        {
          tag: 'title',
          dynamicContent: ({ user }) =>
            user?.info ? `Edit ${user?.info.display_name}` : '',
        },
        {
          tag: 'meta',
          name: { attribute: 'name', value: 'description' },
          content: seo.PROFILE_ACCESS_ROUTE_DESCRIPTION,
        },
      ],
      theme: THEME_DEFAULT,
    },
    component: () =>
      import(/* webpackChunkName: 'profile' */ '@views/Profile.vue'),
    children: [
      {
        path: 'access',
        name: routes.PROFILE_ACCESS_ROUTE,
        meta: {
          widget: {
            component: 'Access',
          },
          breadcrumb: {
            head: { label: 'Profile', to: { name: routes.PROFILE_ROUTE } },
            tail: {
              label: 'API Access',
              to: { name: routes.PROFILE_ACCESS_ROUTE },
            },
          },
          seo: [{ tag: 'title', content: 'API Access' }],
        },
        component: () =>
          import(
            /* webpackChunkName: 'profile' */ '@components/Profile/Panels/Access.vue'
          ),
      },
      {
        path: 'account',
        name: routes.PROFILE_ACCOUNT_ROUTE,
        meta: {
          breadcrumb: {
            head: { label: 'Profile', to: { name: routes.PROFILE_ROUTE } },
            tail: {
              label: 'Account',
              to: { name: routes.PROFILE_ACCOUNT_ROUTE },
            },
          },
        },
        component: () =>
          import(
            /* webpackChunkName: 'profile' */ '@components/Profile/Panels/Account.vue'
          ),
      },
      {
        path: 'privacy',
        name: routes.PROFILE_PRIVACY_ROUTE,
        meta: {
          breadcrumb: {
            head: { label: 'Profile', to: { name: routes.PROFILE_ROUTE } },
            tail: {
              label: 'Privacy',
              to: { name: routes.PROFILE_PRIVACY_ROUTE },
            },
          },
          seo: [{ tag: 'title', content: 'Privacy' }],
        },
        component: () =>
          import(
            /* webpackChunkName: 'profile' */ '@components/Profile/Panels/Privacy.vue'
          ),
      },
      {
        path: 'media',
        name: routes.PROFILE_MEDIA_ROUTE,
        meta: {
          breadcrumb: {
            head: { label: 'Profile', to: { name: routes.PROFILE_ROUTE } },
            tail: { label: 'Media', to: { name: routes.PROFILE_MEDIA_ROUTE } },
          },
          seo: [{ tag: 'title', content: 'Media' }],
          widget: {
            component: 'InlineMediaForm',
          },
        },
        component: () =>
          import(
            /* webpackChunkName: 'profile' */ '@components/Profile/Panels/Media.vue'
          ),
      },
      {
        path: 'monetization',
        redirect: { name: routes.PROFILE_MONETIZATION_HOW_ROUTE },
      },
      {
        path: 'monetization/how-it-works',
        name: routes.PROFILE_MONETIZATION_HOW_ROUTE,
        meta: {
          scope: ['global:monetization', 'user:wallets'],
          breadcrumb: {
            head: { label: 'Profile', to: { name: routes.PROFILE_ROUTE } },
            monetization: {
              label: 'Monetization',
              to: { name: routes.PROFILE_MONETIZATION_HOW_ROUTE },
            },
            tail: {
              label: 'How it works',
              to: { name: routes.PROFILE_MONETIZATION_HOW_ROUTE },
            },
          },
          seo: [{ tag: 'title', content: 'Monetization' }],
        },
        component: () =>
          import(
            /* webpackChunkName: 'profile-monetization' */ '@components/Monetization/Profile/HowItWorks.vue'
          ),
      },
      {
        path: 'monetization/dashboard',
        name: routes.PROFILE_MONETIZATION_ROUTE,
        meta: {
          scope: ['global:monetization', 'user:wallets'],
          breadcrumb: {
            head: { label: 'Profile', to: { name: routes.PROFILE_ROUTE } },
            monetization: {
              label: 'Monetization',
              to: { name: routes.PROFILE_MONETIZATION_HOW_ROUTE },
            },
            tail: {
              label: 'Dashboard',
              to: { name: routes.PROFILE_MONETIZATION_ROUTE },
            },
          },
          seo: [{ tag: 'title', content: 'Monetization' }],
        },
        component: () =>
          import(
            /* webpackChunkName: 'profile-monetization' */ '@components/Monetization/Profile/Monetization.vue'
          ),
      },
      {
        path: 'monetization/transactions',
        name: routes.PROFILE_MONETIZATION_TRANSACTIONS_ROUTE,
        meta: {
          scope: ['global:monetization', 'user:wallets'],
          breadcrumb: {
            head: { label: 'Profile', to: { name: routes.PROFILE_ROUTE } },
            monetization: {
              label: 'Monetization',
              to: { name: routes.PROFILE_MONETIZATION_HOW_ROUTE },
            },
            tail: {
              label: 'Transactions',
              to: { name: routes.PROFILE_MONETIZATION_TRANSACTIONS_ROUTE },
            },
          },
        },
        component: () =>
          import(
            /* webpackChunkName: 'profile-monetization' */ '@components/Monetization/Profile/Transactions.vue'
          ),
      },
      {
        path: 'payments',
        name: routes.PROFILE_MONETIZATION_PAYMENTS_ROUTE,
        meta: {
          scope: ['global:monetization', 'user:registered'],
          breadcrumb: {
            head: { label: 'Profile', to: { name: routes.PROFILE_ROUTE } },
            tail: {
              label: 'Payments',
              to: { name: routes.PROFILE_MONETIZATION_PAYMENTS_ROUTE },
            },
          },
        },
        component: () =>
          import(
            /* webpackChunkName: 'profile-monetization' */ '@components/Monetization/Profile/Payments.vue'
          ),
      },
      {
        path: 'refunds',
        name: routes.PROFILE_MONETIZATION_REFUNDS_ROUTE,
        meta: {
          scope: ['global:monetization', 'user:registered'],
          breadcrumb: {
            head: { label: 'Profile', to: { name: routes.PROFILE_ROUTE } },
            tail: {
              label: 'Payments',
              to: { name: routes.PROFILE_MONETIZATION_PAYMENTS_ROUTE },
            },
          },
        },
        component: () =>
          import(
            /* webpackChunkName: 'profile-monetization' */ '@components/Monetization/Profile/Payments.vue'
          ),
      },
    ],
  },
  {
    path: '/closed',
    name: routes.ACCOUNT_DELETED_ROUTE,
    component: () =>
      import(/* webpackChunkName: 'profile' */ '@views/AccountDeleted.vue'),
  },
  {
    path: '/connect',
    name: routes.CONNECT_DEVICE_ROUTE,
    component: () =>
      import(
        /* webpackChunkName: "connect-device" */ '@views/ConnectDevice.vue'
      ),
  },
  {
    path: '/authorize',
    name: routes.AUTHORIZE_ROUTE,
    component: () =>
      import(/* webpackChunkName: "authorize" */ '@views/Authorize.vue'),
  },
  {
    path: '/connect',
    name: routes.CONNECT_DEVICE_ROUTE,
    component: () =>
      import(
        /* webpackChunkName: "connect-device" */ '@views/ConnectDevice.vue'
      ),
  },
]
