import { queryString, unixTimeOrTs } from '@helpers/utils.js'
import { PRIVATE_BASE_URL } from '@config'
import { createClient } from '@services'
import {
  REPORT_RESOURCE_FILTER,
  STANDARD_EVENT_TYPES,
  UNIQUE_EVENT_TYPES,
  GROUP_BY_OPTIONS,
  METRICS_TYPES,
  RECORD_TYPES,
} from '@config/chartOptions.js'
import qs from 'qs'

const { get, post, put } = createClient()

export async function getMetricsByGame(gameId, query) {
  const { data } = await get(
    `${PRIVATE_BASE_URL}/metrics/games/@${gameId}${queryString(query)}`
  )

  return data
}

export async function getMetricsByMod(gameId, modId, query) {
  const { data } = await get(
    `${PRIVATE_BASE_URL}/metrics/games/@${gameId}/mods/@${modId}${queryString(
      query
    )}`
  )

  return data
}

export async function getMetricsForSite(query) {
  const { data } = await get(`${PRIVATE_BASE_URL}/metrics${queryString(query)}`)

  return data
}

// STATUS PAGE METRICS
export async function getMetricsStatusRequest() {
  const { data } = await get(`${PRIVATE_BASE_URL}/metrics/status`)

  return data
}

// GAME/MOD METRICS
export async function getDownloadsRequest({
  gameId,
  dateFrom,
  dateTo,
  groupBy,
  recordType,
  modId,
  site,
}) {
  const query = {
    metrics_type: METRICS_TYPES.STANDARD,
    date_from: unixTimeOrTs(dateFrom),
    date_to: unixTimeOrTs(dateTo),
    group_by: groupBy,
    record_type: recordType,
    event_type: STANDARD_EVENT_TYPES.MOD_DOWNLOAD,
  }

  return modId
    ? getMetricsByMod(gameId, modId, query)
    : site
      ? getMetricsForSite(query)
      : getMetricsByGame(gameId, query)
}

export async function getDailyDownloadsRequest({
  metricsType,
  gameId,
  dateFrom,
  dateTo,
  groupBy,
  modId,
  site,
}) {
  const query = {
    metrics_type: metricsType,
    date_from: unixTimeOrTs(dateFrom),
    date_to: unixTimeOrTs(dateTo),
    group_by: groupBy,
    record_type: RECORD_TYPES.DAILY,
    event_type: STANDARD_EVENT_TYPES.MOD_DOWNLOAD,
  }

  return modId
    ? getMetricsByMod(gameId, modId, query)
    : site
      ? getMetricsForSite(query)
      : getMetricsByGame(gameId, query)
}

export async function getMonthlyDownloadsRequest({
  metricsType,
  gameId,
  dateFrom,
  dateTo,
  groupBy,
  modId,
  site,
}) {
  const query = {
    metrics_type: metricsType,
    date_from: unixTimeOrTs(dateFrom),
    date_to: unixTimeOrTs(dateTo),
    group_by: groupBy,
    record_type: RECORD_TYPES.MONTHLY,
    event_type: STANDARD_EVENT_TYPES.MOD_DOWNLOAD,
  }

  return modId
    ? getMetricsByMod(gameId, modId, query)
    : site
      ? getMetricsForSite(query)
      : getMetricsByGame(gameId, query)
}

export async function getTotalDownloadsRequest({
  metricsType,
  gameId,
  dateFrom,
  dateTo,
  groupBy,
  modId,
  site,
}) {
  const query = {
    metrics_type: metricsType,
    date_from: unixTimeOrTs(dateFrom),
    date_to: unixTimeOrTs(dateTo),
    record_type: RECORD_TYPES.TOTAL,
    event_type: STANDARD_EVENT_TYPES.MOD_DOWNLOAD,
  }

  if (groupBy) {
    query['group_by'] = groupBy
  }

  return modId
    ? getMetricsByMod(gameId, modId, query)
    : site
      ? getMetricsForSite(query)
      : getMetricsByGame(gameId, query)
}

export async function getSubscriptionRequest({
  gameId,
  dateFrom,
  dateTo,
  recordType,
  groupBy,
  modId,
  site,
  filter,
}) {
  const query = {
    metrics_type: METRICS_TYPES.STANDARD,
    date_from: unixTimeOrTs(dateFrom),
    date_to: unixTimeOrTs(dateTo),
    group_by: groupBy,
    record_type: recordType,
    filter: filter,
    event_type: modId
      ? STANDARD_EVENT_TYPES.MOD_SUBSCRIBE
      : STANDARD_EVENT_TYPES.GAME_SUBSCRIBE,
  }

  return modId
    ? getMetricsByMod(gameId, modId, query)
    : site
      ? getMetricsForSite(query)
      : getMetricsByGame(gameId, query)
}

export async function getDailySubscriptionsRequest({
  metricsType,
  gameId,
  dateFrom,
  dateTo,
  groupBy,
  modId,
  site,
}) {
  const query = {
    metrics_type: metricsType,
    date_from: unixTimeOrTs(dateFrom),
    date_to: unixTimeOrTs(dateTo),
    group_by: groupBy,
    record_type: RECORD_TYPES.DAILY,
    event_type: modId
      ? STANDARD_EVENT_TYPES.MOD_SUBSCRIBE
      : STANDARD_EVENT_TYPES.GAME_SUBSCRIBE,
  }

  return modId
    ? getMetricsByMod(gameId, modId, query)
    : site
      ? getMetricsForSite(query)
      : getMetricsByGame(gameId, query)
}

export async function getMonthlySubscriptionsRequest({
  metricsType,
  gameId,
  dateFrom,
  dateTo,
  groupBy,
  modId,
  site,
}) {
  const query = {
    metrics_type: metricsType,
    date_from: unixTimeOrTs(dateFrom),
    date_to: unixTimeOrTs(dateTo),
    group_by: groupBy,
    record_type: RECORD_TYPES.MONTHLY,
    event_type: modId
      ? STANDARD_EVENT_TYPES.MOD_SUBSCRIBE
      : STANDARD_EVENT_TYPES.GAME_SUBSCRIBE,
  }

  return modId
    ? getMetricsByMod(gameId, modId, query)
    : site
      ? getMetricsForSite(query)
      : getMetricsByGame(gameId, query)
}

export async function getSubscriptionResultForRange({
  metricsType,
  gameId,
  dateFrom,
  dateTo,
  modId,
  site,
}) {
  const query = {
    metrics_type: metricsType,
    record_type: RECORD_TYPES.TOTAL,
    event_type: modId
      ? STANDARD_EVENT_TYPES.MOD_SUBSCRIBE
      : STANDARD_EVENT_TYPES.GAME_SUBSCRIBE,
    date_from: unixTimeOrTs(dateFrom),
    date_to: unixTimeOrTs(dateTo),
  }

  return modId
    ? getMetricsByMod(gameId, modId, query)
    : site
      ? getMetricsForSite(query)
      : getMetricsByGame(gameId, query)
}

export function getTotalActiveUsersRequest({
  metricsType,
  gameId,
  dateFrom,
  dateTo,
  modId,
  site,
}) {
  const query = {
    metrics_type: metricsType,
    record_type: RECORD_TYPES.TOTAL,
    event_type: Object.values(UNIQUE_EVENT_TYPES).slice(0, 2).join(';'),
    date_from: unixTimeOrTs(dateFrom),
    date_to: unixTimeOrTs(dateTo),
  }

  return modId
    ? getMetricsByMod(gameId, modId, query)
    : site
      ? getMetricsForSite(query)
      : getMetricsByGame(gameId, query)
}

export function getMonthlyActiveUsersRequest({
  metricsType,
  gameId,
  dateFrom,
  dateTo,
  modId,
  site,
}) {
  const query = {
    metrics_type: metricsType,
    record_type: RECORD_TYPES.MONTHLY,
    event_type: UNIQUE_EVENT_TYPES.ACTIVE_USER_FULL,
    date_from: unixTimeOrTs(dateFrom),
    date_to: unixTimeOrTs(dateTo),
  }

  return modId
    ? getMetricsByMod(gameId, modId, query)
    : site
      ? getMetricsForSite(query)
      : getMetricsByGame(gameId, query)
}

export function getMonthlyAuthUsersRequest({
  metricsType,
  gameId,
  dateFrom,
  dateTo,
  modId,
  site,
}) {
  const query = {
    metrics_type: metricsType,
    record_type: RECORD_TYPES.MONTHLY,
    event_type: UNIQUE_EVENT_TYPES.ACTIVE_USER_AUTH,
    date_from: unixTimeOrTs(dateFrom),
    date_to: unixTimeOrTs(dateTo),
  }

  return modId
    ? getMetricsByMod(gameId, modId, query)
    : site
      ? getMetricsForSite(query)
      : getMetricsByGame(gameId, query)
}

export async function getPlatformUsersRequest({
  metricsType,
  gameId,
  dateFrom,
  dateTo,
  modId,
  eventType,
  site,
}) {
  const query = {
    metrics_type: metricsType,
    date_from: unixTimeOrTs(dateFrom),
    date_to: unixTimeOrTs(dateTo),
    group_by: GROUP_BY_OPTIONS.PLATFORM,
    event_type: eventType,
    record_type: RECORD_TYPES.DAILY,
  }
  return modId
    ? getMetricsByMod(gameId, modId, query)
    : site
      ? getMetricsForSite(query)
      : getMetricsByGame(gameId, query)
}

export async function getCountryUsersRequest({
  metricsType,
  gameId,
  dateFrom,
  dateTo,
  modId,
  eventType,
  site,
}) {
  const query = {
    metrics_type: metricsType,
    date_from: unixTimeOrTs(dateFrom),
    date_to: unixTimeOrTs(dateTo),
    group_by: GROUP_BY_OPTIONS.LOCATION,
    event_type: eventType,
    record_type: RECORD_TYPES.TOTAL,
  }
  return modId
    ? getMetricsByMod(gameId, modId, query)
    : site
      ? getMetricsForSite(query)
      : getMetricsByGame(gameId, query)
}

export async function getAllUsersRequest({
  gameId,
  dateFrom,
  dateTo,
  groupBy,
  recordType,
  modId,
  site,
}) {
  const query = {
    metrics_type: METRICS_TYPES.UNIQUE,
    date_from: unixTimeOrTs(dateFrom),
    date_to: unixTimeOrTs(dateTo),
    group_by: groupBy,
    event_type: UNIQUE_EVENT_TYPES.ACTIVE_USER_FULL,
    record_type: recordType,
  }
  return modId
    ? getMetricsByMod(gameId, modId, query)
    : site
      ? getMetricsForSite(query)
      : getMetricsByGame(gameId, query)
}

export async function getAuthUsersRequest({
  gameId,
  dateFrom,
  dateTo,
  groupBy,
  recordType,
  modId,
  site,
}) {
  const query = {
    metrics_type: METRICS_TYPES.UNIQUE,
    date_from: unixTimeOrTs(dateFrom),
    date_to: unixTimeOrTs(dateTo),
    group_by: groupBy,
    event_type: UNIQUE_EVENT_TYPES.ACTIVE_USER_AUTH,
    record_type: recordType,
  }
  return modId
    ? getMetricsByMod(gameId, modId, query)
    : site
      ? getMetricsForSite(query)
      : getMetricsByGame(gameId, query)
}

export async function getModsAddedRequest({
  gameId,
  dateFrom,
  dateTo,
  recordType,
  site,
}) {
  const query = {
    metrics_type: METRICS_TYPES.STANDARD,
    date_from: unixTimeOrTs(dateFrom),
    date_to: unixTimeOrTs(dateTo),
    event_type: STANDARD_EVENT_TYPES.MOD_ADDED,
    record_type: recordType,
  }
  return site ? getMetricsForSite(query) : getMetricsByGame(gameId, query)
}

export async function getModsUpdatedRequest({
  gameId,
  dateFrom,
  dateTo,
  recordType,
  site,
}) {
  const query = {
    metrics_type: METRICS_TYPES.STANDARD,
    date_from: unixTimeOrTs(dateFrom),
    date_to: unixTimeOrTs(dateTo),
    event_type: STANDARD_EVENT_TYPES.MOD_UPDATED,
    record_type: recordType,
  }
  return site ? getMetricsForSite(query) : getMetricsByGame(gameId, query)
}

export async function getModsCommentsRequest({
  gameId,
  dateFrom,
  dateTo,
  recordType,
  modId,
  site,
}) {
  const query = {
    metrics_type: METRICS_TYPES.STANDARD,
    date_from: unixTimeOrTs(dateFrom),
    date_to: unixTimeOrTs(dateTo),
    event_type: STANDARD_EVENT_TYPES.MOD_COMMENT,
    record_type: recordType,
  }
  return modId
    ? getMetricsByMod(gameId, modId, query)
    : site
      ? getMetricsForSite(query)
      : getMetricsByGame(gameId, query)
}

export async function getModsFileAddedRequest({
  gameId,
  dateFrom,
  dateTo,
  recordType,
  modId,
  site,
}) {
  const query = {
    metrics_type: METRICS_TYPES.STANDARD,
    date_from: unixTimeOrTs(dateFrom),
    date_to: unixTimeOrTs(dateTo),
    event_type: STANDARD_EVENT_TYPES.MOD_FILE_ADDED,
    record_type: recordType,
  }
  return modId
    ? getMetricsByMod(gameId, modId, query)
    : site
      ? getMetricsForSite(query)
      : getMetricsByGame(gameId, query)
}

export async function getGuidesAddedRequest({
  gameId,
  dateFrom,
  dateTo,
  recordType,
  site,
}) {
  const query = {
    metrics_type: METRICS_TYPES.STANDARD,
    date_from: unixTimeOrTs(dateFrom),
    date_to: unixTimeOrTs(dateTo),
    event_type: STANDARD_EVENT_TYPES.GUIDE_ADDED,
    record_type: recordType,
  }

  return site ? getMetricsForSite(query) : getMetricsByGame(gameId, query)
}

export async function getGuidesCommentsRequest({
  gameId,
  dateFrom,
  dateTo,
  recordType,
  site,
}) {
  const query = {
    metrics_type: METRICS_TYPES.STANDARD,
    date_from: unixTimeOrTs(dateFrom),
    date_to: unixTimeOrTs(dateTo),
    event_type: STANDARD_EVENT_TYPES.GUIDE_COMMENT,
    record_type: recordType,
  }
  return site ? getMetricsForSite(query) : getMetricsByGame(gameId, query)
}

export async function getCreatorJoinedRequest({
  gameId,
  dateFrom,
  dateTo,
  recordType,
  modId,
  site,
}) {
  const query = {
    metrics_type: METRICS_TYPES.STANDARD,
    date_from: unixTimeOrTs(dateFrom),
    date_to: unixTimeOrTs(dateTo),
    event_type: STANDARD_EVENT_TYPES.CREATOR_JOINED,
    record_type: recordType,
  }

  return modId
    ? getMetricsByMod(gameId, modId, query)
    : site
      ? getMetricsForSite(query)
      : getMetricsByGame(gameId, query)
}

export async function getUserJoinedRequest({
  gameId,
  dateFrom,
  dateTo,
  recordType,
  site,
}) {
  const query = {
    metrics_type: METRICS_TYPES.STANDARD,
    date_from: unixTimeOrTs(dateFrom),
    date_to: unixTimeOrTs(dateTo),
    event_type: STANDARD_EVENT_TYPES.USER_JOINED,
    record_type: recordType,
  }
  return site ? getMetricsForSite(query) : getMetricsByGame(gameId, query)
}

export async function getApiKeyRequest({
  gameId,
  dateFrom,
  dateTo,
  groupBy,
  recordType,
  site,
}) {
  const query = {
    metrics_type: METRICS_TYPES.STANDARD,
    date_from: unixTimeOrTs(dateFrom),
    date_to: unixTimeOrTs(dateTo),
    group_by: groupBy,
    event_type: STANDARD_EVENT_TYPES.API_KEY,
    record_type: recordType,
  }
  return site ? getMetricsForSite(query) : getMetricsByGame(gameId, query)
}

export async function getBandwidthRequest({
  gameId,
  dateFrom,
  dateTo,
  groupBy,
  recordType,
  site,
}) {
  const query = {
    metrics_type: METRICS_TYPES.STANDARD,
    date_from: unixTimeOrTs(dateFrom),
    date_to: unixTimeOrTs(dateTo),
    group_by: groupBy,
    event_type: STANDARD_EVENT_TYPES.BANDWIDTH,
    record_type: recordType,
  }
  return site ? getMetricsForSite(query) : getMetricsByGame(gameId, query)
}

export async function getFilesAddedRequest({
  gameId,
  dateFrom,
  dateTo,
  groupBy,
  recordType,
  site,
}) {
  const query = {
    metrics_type: METRICS_TYPES.STANDARD,
    date_from: unixTimeOrTs(dateFrom),
    date_to: unixTimeOrTs(dateTo),
    group_by: groupBy,
    event_type: STANDARD_EVENT_TYPES.FILE_ADDED,
    record_type: recordType,
  }
  return site ? getMetricsForSite(query) : getMetricsByGame(gameId, query)
}

export async function getOpenedModReportsRequest({
  gameId,
  dateFrom,
  dateTo,
  recordType,
  site,
}) {
  const query = {
    metrics_type: METRICS_TYPES.STANDARD,
    date_from: unixTimeOrTs(dateFrom),
    date_to: unixTimeOrTs(dateTo),
    filter: { [GROUP_BY_OPTIONS.RESOURCE]: REPORT_RESOURCE_FILTER.MOD },
    group_by: GROUP_BY_OPTIONS.REPORT,
    event_type: STANDARD_EVENT_TYPES.REPORT_OPENED,
    record_type: recordType,
  }
  return site ? getMetricsForSite(query) : getMetricsByGame(gameId, query)
}

export async function getClosedModReportsRequest({
  gameId,
  dateFrom,
  dateTo,
  recordType,
  site,
}) {
  const query = {
    metrics_type: METRICS_TYPES.STANDARD,
    date_from: unixTimeOrTs(dateFrom),
    date_to: unixTimeOrTs(dateTo),
    filter: { [GROUP_BY_OPTIONS.RESOURCE]: REPORT_RESOURCE_FILTER.MOD },
    group_by: GROUP_BY_OPTIONS.REPORT,
    event_type: STANDARD_EVENT_TYPES.REPORT_CLOSED,
    record_type: recordType,
  }
  return site ? getMetricsForSite(query) : getMetricsByGame(gameId, query)
}

export async function getDeletedModsRequest({
  gameId,
  dateFrom,
  dateTo,
  recordType,
  site,
}) {
  const query = {
    metrics_type: METRICS_TYPES.STANDARD,
    date_from: unixTimeOrTs(dateFrom),
    date_to: unixTimeOrTs(dateTo),
    filter: { [GROUP_BY_OPTIONS.RESOURCE]: REPORT_RESOURCE_FILTER.MOD },
    group_by: GROUP_BY_OPTIONS.PERMISSION_UPDATE,
    event_type: STANDARD_EVENT_TYPES.PERMISSION_CHANGE,
    record_type: recordType,
  }
  return site ? getMetricsForSite(query) : getMetricsByGame(gameId, query)
}

export async function getOpenedUserReportsRequest({
  gameId,
  dateFrom,
  dateTo,
  recordType,
  site,
}) {
  const query = {
    metrics_type: METRICS_TYPES.STANDARD,
    date_from: unixTimeOrTs(dateFrom),
    date_to: unixTimeOrTs(dateTo),
    filter: { [GROUP_BY_OPTIONS.RESOURCE]: REPORT_RESOURCE_FILTER.USER },
    group_by: GROUP_BY_OPTIONS.REPORT,
    event_type: STANDARD_EVENT_TYPES.REPORT_OPENED,
    record_type: recordType,
  }
  return site ? getMetricsForSite(query) : getMetricsByGame(gameId, query)
}

export async function getClosedUserReportsRequest({
  gameId,
  dateFrom,
  dateTo,
  recordType,
  site,
}) {
  const query = {
    metrics_type: METRICS_TYPES.STANDARD,
    date_from: unixTimeOrTs(dateFrom),
    date_to: unixTimeOrTs(dateTo),
    filter: { [GROUP_BY_OPTIONS.RESOURCE]: REPORT_RESOURCE_FILTER.USER },
    group_by: GROUP_BY_OPTIONS.REPORT,
    event_type: STANDARD_EVENT_TYPES.REPORT_CLOSED,
    record_type: recordType,
  }
  return site ? getMetricsForSite(query) : getMetricsByGame(gameId, query)
}

export async function getUsersBannedRequest({
  gameId,
  dateFrom,
  dateTo,
  recordType,
  site,
}) {
  const query = {
    metrics_type: METRICS_TYPES.STANDARD,
    date_from: unixTimeOrTs(dateFrom),
    date_to: unixTimeOrTs(dateTo),
    filter: { [GROUP_BY_OPTIONS.RESOURCE]: REPORT_RESOURCE_FILTER.USER },
    group_by: GROUP_BY_OPTIONS.PERMISSION_UPDATE,
    event_type: STANDARD_EVENT_TYPES.PERMISSION_CHANGE,
    record_type: recordType,
  }
  return site ? getMetricsForSite(query) : getMetricsByGame(gameId, query)
}

// Trending queries.
export async function getTrendingModDownloadsRequest({
  gameId,
  dateFrom,
  dateTo,
  modId,
  site,
  filter,
}) {
  const query = {
    metrics_type: METRICS_TYPES.STANDARD,
    date_from: unixTimeOrTs(dateFrom),
    date_to: unixTimeOrTs(dateTo),
    record_type: RECORD_TYPES.TRENDING,
    event_type: STANDARD_EVENT_TYPES.MOD_DOWNLOAD,
  }

  if (filter) query.filter = filter

  return modId
    ? getMetricsByMod(gameId, modId, query)
    : site
      ? getMetricsForSite(query)
      : getMetricsByGame(gameId, query)
}

export async function getTrendingCreatorDownloadsRequest({
  gameId,
  dateFrom,
  dateTo,
  modId,
  site,
  filter,
}) {
  const query = {
    metrics_type: METRICS_TYPES.STANDARD,
    date_from: unixTimeOrTs(dateFrom),
    date_to: unixTimeOrTs(dateTo),
    record_type: RECORD_TYPES.TRENDING,
    event_type: STANDARD_EVENT_TYPES.CREATOR_DOWNLOAD,
  }

  if (filter) query.filter = filter

  return modId
    ? getMetricsByMod(gameId, modId, query)
    : site
      ? getMetricsForSite(query)
      : getMetricsByGame(gameId, query)
}

// MONETIZATION METRICS
export async function getMonetizationMetrics({
  gameId,
  dateFrom,
  dateTo,
  modId,
  site,
  groupBy,
  filter,
  eventType,
  recordType,
  metricsType,
}) {
  const query = {
    metrics_type: metricsType || METRICS_TYPES.STANDARD,
    record_type: recordType,
    event_type: eventType,
    date_from: unixTimeOrTs(dateFrom),
    date_to: unixTimeOrTs(dateTo),
    group_by: groupBy,
    filter,
  }
  return modId
    ? getMetricsByMod(gameId, modId, query)
    : site
      ? getMetricsForSite(query)
      : getMetricsByGame(gameId, query)
}

// SESSION SETTINGS
export async function getCCUSecretForGame(gameNameId) {
  const { data } = await get(
    `${PRIVATE_BASE_URL}/metrics/games/@${gameNameId}/settings`
  )
  return data
}

export async function createCCUSecretForGame(gameNameId) {
  const { data } = await post(
    `${PRIVATE_BASE_URL}/metrics/games/@${gameNameId}/settings`
  )
  return data
}

export async function updateCCUSecretForGame(gameNameId, sessionTracking) {
  const { data } = await put(
    `${PRIVATE_BASE_URL}/metrics/games/@${gameNameId}/settings`,
    qs.stringify({ session_tracking: sessionTracking })
  )
  return data
}

// SESSION METRICS
export async function getPlatformPlaytime({
  gameId,
  dateFrom,
  dateTo,
  modId,
  recordType,
  groupBy,
  site,
  filter,
}) {
  const query = {
    metrics_type: METRICS_TYPES.STANDARD,
    date_from: unixTimeOrTs(dateFrom),
    date_to: unixTimeOrTs(dateTo),
    group_by: groupBy,
    record_type: recordType,
    event_type: `${STANDARD_EVENT_TYPES.CCU_PLATFORM_PLAYTIME}_${recordType}`,
  }

  if (filter) query.filter = filter

  return modId
    ? getMetricsByMod(gameId, modId, query)
    : site
      ? getMetricsForSite(query)
      : getMetricsByGame(gameId, query)
}

export async function getSessionLength(gameId, dateFrom, dateTo) {
  const query = {
    metrics_type: METRICS_TYPES.STANDARD,
    date_from: unixTimeOrTs(dateFrom),
    date_to: unixTimeOrTs(dateTo),
    record_type: RECORD_TYPES.SESSION,
    event_type: STANDARD_EVENT_TYPES.CCU_SESSION_LENGTH,
  }

  return getMetricsByGame(gameId, query)
}

export async function getSessionPlatform(gameId, dateFrom, dateTo) {
  const query = {
    metrics_type: METRICS_TYPES.STANDARD,
    date_from: unixTimeOrTs(dateFrom),
    date_to: unixTimeOrTs(dateTo),
    group_by: GROUP_BY_OPTIONS.PLATFORM,
    record_type: RECORD_TYPES.TOTAL,
    event_type: STANDARD_EVENT_TYPES.CCU_SESSION_PLATFORM,
  }

  return getMetricsByGame(gameId, query)
}

export async function getCCUByPlatform({
  gameId,
  dateFrom,
  dateTo,
  modId,
  recordType,
  groupBy,
  site,
  filter,
}) {
  const query = {
    metrics_type: METRICS_TYPES.STANDARD,
    date_from: unixTimeOrTs(dateFrom),
    date_to: unixTimeOrTs(dateTo),
    group_by: groupBy,
    record_type: recordType,
    event_type: `${STANDARD_EVENT_TYPES.CCU_PLATFORM}_${recordType}`,
  }

  if (filter) query.filter = filter

  return modId
    ? getMetricsByMod(gameId, modId, query)
    : site
      ? getMetricsForSite(query)
      : getMetricsByGame(gameId, query)
}

export async function getCCUPeak({
  gameId,
  dateFrom,
  dateTo,
  modId,
  recordType,
  groupBy,
  site,
  filter,
}) {
  const query = {
    metrics_type: METRICS_TYPES.STANDARD,
    date_from: unixTimeOrTs(dateFrom),
    date_to: unixTimeOrTs(dateTo),
    group_by: groupBy,
    record_type: recordType,
    event_type: `${STANDARD_EVENT_TYPES.CCU_PEAK_OVER_TIME}_${recordType}`,
  }

  if (filter) query.filter = filter

  return modId
    ? getMetricsByMod(gameId, modId, query)
    : site
      ? getMetricsForSite(query)
      : getMetricsByGame(gameId, query)
}

export async function getCCUModSummary(gameId, dateFrom, dateTo) {
  const query = {
    metrics_type: METRICS_TYPES.STANDARD,
    date_from: unixTimeOrTs(dateFrom),
    date_to: unixTimeOrTs(dateTo),
    record_type: RECORD_TYPES.SESSION,
    event_type: STANDARD_EVENT_TYPES.CCU_MAX_SUM_MODS,
  }

  return getMetricsByGame(gameId, query)
}

export async function getCCUSummary(gameId, dateFrom, dateTo) {
  const query = {
    metrics_type: METRICS_TYPES.STANDARD,
    date_from: unixTimeOrTs(dateFrom),
    date_to: unixTimeOrTs(dateTo),
    record_type: RECORD_TYPES.SESSION,
    event_type: STANDARD_EVENT_TYPES.CCU_MAX_SUM_USERS,
  }

  return getMetricsByGame(gameId, query)
}

export async function getSessionPlaytimeSummary(gameId, dateFrom, dateTo) {
  const query = {
    metrics_type: METRICS_TYPES.STANDARD,
    date_from: unixTimeOrTs(dateFrom),
    date_to: unixTimeOrTs(dateTo),
    record_type: RECORD_TYPES.SESSION,
    event_type: STANDARD_EVENT_TYPES.CCU_SESSION_PLAYTIME,
  }

  return getMetricsByGame(gameId, query)
}

export async function getSessionModSummary(gameId, dateFrom, dateTo) {
  const query = {
    metrics_type: METRICS_TYPES.STANDARD,
    date_from: unixTimeOrTs(dateFrom),
    date_to: unixTimeOrTs(dateTo),
    record_type: RECORD_TYPES.SESSION,
    event_type: STANDARD_EVENT_TYPES.CCU_SESSION_MODS,
  }

  return getMetricsByGame(gameId, query)
}

export async function getCCUTotalSummary(gameId) {
  const query = {
    metrics_type: METRICS_TYPES.STANDARD,
    date_from: 1,
    date_to: 1,
    record_type: RECORD_TYPES.SESSION,
    event_type: STANDARD_EVENT_TYPES.CCU_TOTAL,
  }

  return getMetricsByGame(gameId, query)
}

export async function getCCULocationPlaytime(gameId, dateFrom, dateTo) {
  const query = {
    metrics_type: METRICS_TYPES.STANDARD,
    date_from: unixTimeOrTs(dateFrom),
    date_to: unixTimeOrTs(dateTo),
    record_type: RECORD_TYPES.TOTAL,
    group_by: GROUP_BY_OPTIONS.LOCATION,
    event_type: STANDARD_EVENT_TYPES.CCU_COUNTRY_PLAYTIME,
  }

  return getMetricsByGame(gameId, query)
}

export async function getCCULocationPlaytimeActive(gameId) {
  const query = {
    metrics_type: METRICS_TYPES.STANDARD,
    date_from: 1,
    date_to: 1,
    record_type: RECORD_TYPES.SESSION,
    event_type: STANDARD_EVENT_TYPES.CCU_COUNTRY_ACTIVE,
  }

  return getMetricsByGame(gameId, query)
}

export async function getCCUModsUsersActive(gameId) {
  const query = {
    metrics_type: METRICS_TYPES.STANDARD,
    date_from: 1,
    date_to: 1,
    record_type: RECORD_TYPES.SESSION,
    event_type: STANDARD_EVENT_TYPES.CCU_MODS_USERS_ACTIVE,
  }

  return getMetricsByGame(gameId, query)
}

export async function getCreatorModsStatRequest({
  gameId,
  modId,
  dateFrom,
  dateTo,
  recordType,
  filter,
}) {
  const query = {
    metrics_type: METRICS_TYPES.STANDARD,
    date_from: unixTimeOrTs(dateFrom),
    date_to: unixTimeOrTs(dateTo),
    record_type: recordType,
    filter: filter,
    event_type: STANDARD_EVENT_TYPES.CCU_CREATORS_MODS_PLAYTIME,
  }

  return modId
    ? getMetricsByMod(gameId, modId, query)
    : getMetricsByGame(gameId, query)
}

export async function getCreatorsStatRequest({
  gameId,
  dateFrom,
  dateTo,
  recordType,
  filter,
}) {
  const query = {
    metrics_type: METRICS_TYPES.STANDARD,
    date_from: unixTimeOrTs(dateFrom),
    date_to: unixTimeOrTs(dateTo),
    record_type: recordType,
    filter: filter,
    event_type: STANDARD_EVENT_TYPES.CCU_CREATORS_PLAYTIME,
  }

  return getMetricsByGame(gameId, query)
}

export async function getModPlayersRequest(gameId, modId, column) {
  const query = {
    metrics_type: METRICS_TYPES.STANDARD,
    filter: {
      column: column,
    },
    record_type: RECORD_TYPES.TOTAL,
    event_type: STANDARD_EVENT_TYPES.CCU_MODS_PLAYERS,
  }

  return getMetricsByMod(gameId, modId, query)
}

export async function getCreatorPlayersRequest(gameId, creator, column) {
  const query = {
    metrics_type: METRICS_TYPES.STANDARD,
    filter: {
      column: column,
      creator: creator,
    },
    record_type: RECORD_TYPES.TOTAL,
    event_type: STANDARD_EVENT_TYPES.CCU_CREATORS_PLAYERS,
  }

  return getMetricsByGame(gameId, query)
}

export async function getModsSessionLengthRequest(
  gameId,
  modId,
  dateFrom,
  dateTo
) {
  const query = {
    metrics_type: METRICS_TYPES.STANDARD,
    date_from: unixTimeOrTs(dateFrom),
    date_to: unixTimeOrTs(dateTo),
    record_type: RECORD_TYPES.SESSION,
    event_type: STANDARD_EVENT_TYPES.CCU_MODS_SESION_LENGTH,
  }

  return getMetricsByMod(gameId, modId, query)
}

export async function getDownloadsByTagsRequest({
  gameId,
  dateFrom,
  dateTo,
  recordType,
  filter,
}) {
  const query = {
    record_type: recordType,
    date_from: unixTimeOrTs(dateFrom),
    date_to: unixTimeOrTs(dateTo),
    event_type: STANDARD_EVENT_TYPES.MOD_TAG_DOWNLOADS,
    filter,
    group_by: GROUP_BY_OPTIONS.PLATFORM,
  }

  return getMetricsByGame(gameId, query)
}

export async function getTotalTagCategoryDownloadsRequest(
  gameId,
  dateFrom,
  dateTo,
  filter
) {
  const query = {
    metrics_type: METRICS_TYPES.STANDARD,
    date_from: unixTimeOrTs(dateFrom),
    date_to: unixTimeOrTs(dateTo),
    record_type: RECORD_TYPES.TOTAL,
    event_type: STANDARD_EVENT_TYPES.MOD_TAG_CATEGORY_DOWNLOADS,
    filter,
    group_by: GROUP_BY_OPTIONS.CATEGORY,
  }

  return getMetricsByGame(gameId, query)
}

export async function getDownloadsByCategoriesRequest({
  gameId,
  dateFrom,
  dateTo,
  recordType,
  filter,
}) {
  const query = {
    metrics_type: METRICS_TYPES.STANDARD,
    date_from: unixTimeOrTs(dateFrom),
    date_to: unixTimeOrTs(dateTo),
    record_type: recordType,
    event_type: STANDARD_EVENT_TYPES.MOD_TAG_CATEGORY_DOWNLOADS,
    filter,
    group_by: GROUP_BY_OPTIONS.PLATFORM,
  }

  return getMetricsByGame(gameId, query)
}

export async function getTotalTagDownloadsRequest(
  gameId,
  dateFrom,
  dateTo,
  filter
) {
  const query = {
    metrics_type: METRICS_TYPES.STANDARD,
    date_from: unixTimeOrTs(dateFrom),
    date_to: unixTimeOrTs(dateTo),
    record_type: RECORD_TYPES.TOTAL,
    event_type: STANDARD_EVENT_TYPES.MOD_TAG_DOWNLOADS,
    filter,
    group_by: GROUP_BY_OPTIONS.MOD_TAG,
  }

  return getMetricsByGame(gameId, query)
}

export async function getTrendingTagDownloadsRequest({
  gameId,
  dateFrom,
  dateTo,
  filter,
}) {
  const query = {
    metrics_type: METRICS_TYPES.STANDARD,
    date_from: unixTimeOrTs(dateFrom),
    date_to: unixTimeOrTs(dateTo),
    record_type: RECORD_TYPES.TRENDING,
    event_type: STANDARD_EVENT_TYPES.MOD_TAG_DOWNLOADS,
    filter,
  }

  return getMetricsByGame(gameId, query)
}

export async function getTrendingTagDownloadsByCreatorsRequest({
  gameId,
  dateFrom,
  dateTo,
  filter,
}) {
  const query = {
    metrics_type: METRICS_TYPES.STANDARD,
    date_from: unixTimeOrTs(dateFrom),
    date_to: unixTimeOrTs(dateTo),
    record_type: RECORD_TYPES.TRENDING,
    event_type: STANDARD_EVENT_TYPES.MOD_CREATOR_TAG_DOWNLOADS,
    filter,
  }

  return getMetricsByGame(gameId, query)
}

// Creator page stats.
export async function getCreatorOverallMetricsRequest({ filter }) {
  const query = {
    metrics_type: METRICS_TYPES.STANDARD,
    record_type: RECORD_TYPES.OVERALL,
    event_type: STANDARD_EVENT_TYPES.CREATOR_STATS,
    filter,
  }

  return getMetricsForSite(query)
}

export async function getCreatorDownloadsRequest({
  gameId,
  recordType,
  filter,
}) {
  const query = {
    metrics_type: METRICS_TYPES.STANDARD,
    record_type: recordType,
    event_type: STANDARD_EVENT_TYPES.CREATOR_DOWNLOADS,
    group_by: GROUP_BY_OPTIONS.PLATFORM,
    filter,
  }

  return gameId ? getMetricsByGame(gameId, query) : getMetricsForSite(query)
}

export async function getCreatorSubscribersRequest({
  gameId,
  recordType,
  filter,
}) {
  const query = {
    metrics_type: METRICS_TYPES.STANDARD,
    record_type: recordType,
    event_type: STANDARD_EVENT_TYPES.CREATOR_SUBSCRIBERS,
    group_by: GROUP_BY_OPTIONS.PLATFORM,
    filter,
  }

  return gameId ? getMetricsByGame(gameId, query) : getMetricsForSite(query)
}

export async function getCreatorModsTrendingRequest({ filter }) {
  const query = {
    metrics_type: METRICS_TYPES.STANDARD,
    record_type: RECORD_TYPES.OVERALL,
    event_type: STANDARD_EVENT_TYPES.CREATOR_MODS_OVERALL,
    filter,
  }

  return getMetricsForSite(query)
}

export async function getCreatorCountryDownloadsRequest({
  gameId,
  range,
  filter,
}) {
  const query = {
    metrics_type: METRICS_TYPES.STANDARD,
    record_type: RECORD_TYPES.OVERALL,
    event_type: STANDARD_EVENT_TYPES.CREATOR_COUNTRY_DOWNLOADS,
    filter,
    range,
  }

  return gameId ? getMetricsByGame(gameId, query) : getMetricsForSite(query)
}

export async function getCreatorContributionsRequest({ gameId, filter }) {
  const query = {
    metrics_type: METRICS_TYPES.STANDARD,
    record_type: RECORD_TYPES.OVERALL,
    event_type: STANDARD_EVENT_TYPES.CREATOR_CONTRIBUTIONS,
    filter,
  }

  return gameId ? getMetricsByGame(gameId, query) : getMetricsForSite(query)
}
